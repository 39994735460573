import { server } from './index'
import Qs from 'qs'

const getActivitiess = async (params = {}, serializer) => {
  try {
    const { data } = await server({
      method: 'GET',
      url: '/activities',
      params,
      paramsSerializer: (params) => {
        return Qs.stringify({
          pagination: true,
          ...params,
          ...serializer
        }, {
          arrayFormat: 'brackets'
        })
      }
    })
    return data
  } catch (error) {

  }
}

export default getActivitiess