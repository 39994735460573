import React, { useState, useEffect } from "react";
import {
  Grid,
  TableContainer,
  Table,
  TableSortLabel,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Avatar,
  CircularProgress,
  Typography,
  TablePagination,
} from "@material-ui/core";
import {
  Info,
  Close,
  CheckCircle,
  RadioButtonUnchecked,
} from "@material-ui/icons";
import { updown } from "../../assets";
import { userTypes } from "../../contants";
import styles from "./DataTable.module.css";

const DataTable = ({
  headers,
  data,
  order,
  orderBy,
  onRequestSort,
  onInfo,
  onDelete,
  onSent,
  page,
  totalDocs,
  limit,
  onChangePage,
  communityHasAdmin,
}) => {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(!data);
    return () => {
      setLoading(true);
    };
  }, [data]);

  const createSortHandler = (index) => (event) => {
    onRequestSort(event, headers[index].key);
  };

  const createInfoRequest = (data) => () => {
    onInfo(data);
  };

  const createDeleteRequest = (data) => () => {
    onDelete(data);
  };
  const createSentData = (data) => () => {
    onSent(data);
  };
  const formatValue = (raw, key, type, index) => {
    try {
      const value = key.split(".").reduce((a, b) => a[b], raw);

      switch (type) {
        case "date":
          return new Date(value).toDateString();
        case "avatar":
          return (
            <Avatar
              alt="Member Avatar"
              src={`https://i0.wp.com/${value?.slice(8)}?w=300`}
            />
          );
        case "communityAdmin":
          return index === 0 && page === 1 && communityHasAdmin ? "Admin" : "";
        case "userType":
          const userTypeLabel = Object.keys(userTypes).find(
            (key) => userTypes[key] === value
          );
          return userTypeLabel.toLowerCase() === "administrator"
            ? userTypeLabel
            : "";
        case "isSent":
          const a = value ? (
            <IconButton
              aria-label="Info"
              size="small"
              style={{ color: "#4A0085" }}
              onClick={createSentData(raw)}
            >
              <CheckCircle></CheckCircle>
            </IconButton>
          ) : (
            <IconButton
              aria-label="Info"
              size="small"
              style={{ color: "#4A0085" }}
              onClick={createSentData(raw)}
            >
              <RadioButtonUnchecked></RadioButtonUnchecked>
            </IconButton>
          );
          return a;

        default:
          return value;
      }
    } catch (error) {
      return "";
    }
  };

  return isLoading ? (
    <Grid container justify="center" style={{ marginTop: "50px" }}>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <CircularProgress></CircularProgress>
        <Typography variant="h6">Loading...</Typography>
      </Grid>
    </Grid>
  ) : (
    <>
      <TableContainer>
        <Table size="small" stickyHeader={true}>
          <TableHead>
            <TableRow>
              {headers.map((row, index) => (
                <TableCell key={row.label} className={styles.header}>
                  <TableSortLabel
                    active={false}
                    direction={orderBy === row.key ? order : "desc"}
                    onClick={createSortHandler(index)}
                    hideSortIcon={true}
                  >
                    {row.label}{" "}
                    <img
                      src={updown}
                      alt="sort"
                      style={{
                        width: "16px",
                        height: "16px",
                        marginLeft: "5px",
                      }}
                    ></img>
                  </TableSortLabel>
                </TableCell>
              ))}
              {onInfo && <TableCell className={styles.header}></TableCell>}
              {onDelete && <TableCell className={styles.header}></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row, iHead) => (
                <TableRow key={iHead}>
                  {headers.map((head, iCell) => (
                    <TableCell
                      key={`${head.key}-${iCell}`}
                      className={
                        head.key === "True" ? styles.specialBody : null
                      }
                    >
                      {formatValue(row, head.key, head.type, iHead)}
                    </TableCell>
                  ))}
                  {onInfo && (
                    <TableCell>
                      <IconButton
                        aria-label="Info"
                        size="small"
                        style={{ color: "#4A0085" }}
                        onClick={createInfoRequest(row)}
                      >
                        <Info></Info>
                      </IconButton>
                    </TableCell>
                  )}
                  {onDelete && (
                    <TableCell>
                      <IconButton
                        aria-label="Delete"
                        size="small"
                        style={{ backgroundColor: "#DB093B", color: "#FFFFFF" }}
                        onClick={createDeleteRequest(row)}
                      >
                        <Close fontSize="small"></Close>
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!!page && !!totalDocs && !!onChangePage && (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={totalDocs}
          rowsPerPage={limit}
          page={page - 1}
          onChangePage={onChangePage}
        />
      )}
    </>
  );
};

export default DataTable;
