import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { Sidebar } from '../components'
import { useAuth } from '../context/auth'

const AdminRoute = ({ component: Component, ...rest }) => {
  const { authToken } = useAuth()

  return (
    <Route 
      {...rest} 
      component={props => (
        authToken ? (
          <Grid container>
            <Grid item xs={3} style={{maxWidth: '350px'}}>
              <Sidebar></Sidebar>
            </Grid>
            <Grid item xs={9}>
              <Component {...props}></Component>
            </Grid>
          </Grid>
        ) : (
          <Redirect to="/" />
        )
      )}
    >
    </Route>
  )
}

export default AdminRoute