import React, { useState } from 'react'
import { Routes } from './routes'
import Cookies from 'universal-cookie'
import { AuthContext } from './context/auth'

const App = () => {
  const cookies = new Cookies()
  const existingToken = cookies.get('token')
  const [authToken, setAuthToken] = useState(existingToken)

  const setToken = (token) => {
    if (token) {
      cookies.set('token', token);
    } else {
      cookies.remove('token')
    }
    setAuthToken(token);
  }

  return (
    <AuthContext.Provider value={{ authToken, setToken }}>
      <Routes></Routes>
    </AuthContext.Provider>
  )
}

export default App