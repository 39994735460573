import { server } from './index'

const updateCommunityById = async (id, objUpdate) => {
  try {
    const { data } = await server({
      method: 'PATCH',
      url: `/communities/${id}`,
      data: objUpdate
    })
    return data
  } catch (error) {

  }
}

export default updateCommunityById