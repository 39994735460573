import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const Authorization = cookies.get("token");
const axiosConfig = {
  baseURL: "https://api.localminds.se/v2", //production
  // baseURL: "https://api.localminds.se/dev/v2", //development

  // baseURL: 'http://192.168.100.2:3000/v2', // local
  timeout: 10000,
};

if (Authorization) {
  axiosConfig.headers = {
    Authorization,
  };
}

const instance = axios.create(axiosConfig);

export default instance;
