import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  OutlinedInput,
  InputAdornment,
  Button,
  IconButton,
} from "@material-ui/core";
import { Search, ArrowBackOutlined } from "@material-ui/icons";
import cx from "classnames";
import { dataNormalizer } from "../../helpers";
import { CSVLink } from "react-csv";
import styles from "./PageHeader.module.css";

const PageHeader = ({
  title,
  onAddNew,
  onBack,
  onDelete,
  onSearch,
  csvReport,
  onSplit,
  onResetEnergyModule,
  onExit,
}) => {
  const [search, setSearch] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [csvHeaders, setCsvHeaders] = useState([]);

  useEffect(() => {
    if (csvReport) {
      const { headers, data, fn } = csvReport;

      if (!data && fn && typeof fn === "function") {
        (async function () {
          const data = await fn();
          const { headers: nHeaders, data: nData } = dataNormalizer(
            headers,
            data || []
          );
          setCsvData(nData);
          setCsvHeaders(nHeaders);
        })();

        return;
      }

      const { headers: nHeaders, data: nData } = dataNormalizer(
        headers,
        data || []
      );
      setCsvData(nData);
      setCsvHeaders(nHeaders);
    }
    return () => {
      setCsvData([]);
    };
  }, [csvReport]);

  const createSearchHandler = (e) => {
    (e.key === "Enter" || !e.key) && onSearch(search);
  };

  return (
    <>
      <Grid item xs={3}>
        <Typography variant="h6" classes={{ root: styles.title }}>
          {title}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        {onSearch && (
          <OutlinedInput
            placeholder="Search"
            className={styles.search}
            startAdornment={
              <InputAdornment position="start">
                <IconButton size="small" onClick={createSearchHandler}>
                  <Search edge="start"></Search>
                </IconButton>
              </InputAdornment>
            }
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyUp={createSearchHandler}
          />
        )}
      </Grid>
      <Grid item xs={6} classes={{ item: styles.right }}>
        {onAddNew && (
          <Button
            variant="contained"
            classes={{
              root: cx(styles.btn, styles.add),
              label: styles.btnLabel,
            }}
            onClick={onAddNew}
          >
            Add New
          </Button>
        )}
        {onSplit && (
          <Button
            variant="contained"
            classes={{
              root: cx(styles.btn, styles.split),
              label: styles.btnLabel,
            }}
            onClick={onSplit}
          >
            Split points
          </Button>
        )}
        {onResetEnergyModule && (
          <Button
            variant="contained"
            classes={{
              root: cx(styles.btn, styles.resetEnergyModule),
              label: styles.btnLabel,
            }}
            onClick={onResetEnergyModule}
          >
            Reset Energy
          </Button>
        )}
        {onExit && (
          <Button
            variant="contained"
            classes={{
              root: cx(styles.btn, styles.communityExit),
              label: styles.btnLabel,
            }}
            onClick={onExit}
          >
            Exit/clear
          </Button>
        )}
        {onDelete ? (
          <Button
            variant="contained"
            classes={{
              root: cx(styles.btn, styles.delete),
              label: styles.btnLabel,
            }}
            onClick={onDelete}
          >
            Delete
          </Button>
        ) : csvReport ? (
          <CSVLink
            headers={csvHeaders}
            data={csvData}
            filename={csvReport.filename}
            style={{ textDecoration: "none" }}
          >
            <Button
              variant="contained"
              classes={{
                root: cx(styles.btn, styles.export),
                label: styles.btnLabel,
              }}
            >
              Export
            </Button>
          </CSVLink>
        ) : null}
        {onBack && (
          <IconButton onClick={onBack} size="small" className={styles.back}>
            <ArrowBackOutlined></ArrowBackOutlined>
          </IconButton>
        )}
      </Grid>
    </>
  );
};

export default PageHeader;
