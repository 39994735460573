/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { Grid, IconButton, Typography, Paper } from "@material-ui/core";
import { HighlightOff } from "@material-ui/icons";
import {
  PageHeader,
  DataTable,
  StyledModal,
  ModalDelete,
} from "../../components";
import { getMotionReport } from "../../api";
import styles from "./MotionReport.module.css";

const headers = [
  { label: "Date", key: "createdAt", type: "date" },
  { label: "User ID", key: "userId", type: "text" },
  { label: "Option", key: "motionOption" },
  { label: "Title", key: "motionTitle" },
  { label: "Description", key: "motionDesc" },
  { label: "isApproved", key: "isApproved", type: "text" },
  { label: "isDeclined", key: "isDeclined", type: "text" },
];

const limitData = 100;

const MotionReport = () => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [open, setOpen] = useState(false);
  const [reportList, setReportList] = useState(null);
  const [reportListExport, setReportListExport] = useState(null);
  const [selectedData, setSelectedData] = useState({});
  const [serializer, setSerializer] = useState({});
  const [pagination, setPagination] = useState({});
  const mountedRef = useRef(true);
  const [del, setDel] = useState(false);
  const [selectedMotion, setSelectedMotion] = useState({
    date: "",
    userID: "",
    option: "",
    title: "",
    description: "",
    community: "",
    email: "",
    isApproved: "",
    isDeclined: "",
  });

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    mountedRef.current && fetchAllReports({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serializer, del, selectedMotion]);

  const fetchAllReports = async (params) => {
    try {
      setReportList(null);
      const { docs, ...paginate } = await getMotionReport(
        { ...params, limit: limitData },
        serializer
      );

      if (mountedRef.current) {
        setReportList(docs);
        setPagination(paginate);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchActivityListExport = async (params) => {
    try {
      if (!reportListExport) {
        const limit = 999999999;
        const {
          reports: { docs },
        } = await getMotionReport({ ...params, limit }, serializer);
        setReportListExport(docs);

        return docs;
      }

      return reportListExport;
    } catch (error) {
      mountedRef.current && setReportListExport(null);
    }
  };

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    switch (property) {
      case "user.fullName":
      case "user.email":
      case "user.communityId.name":
        fetchAllReports({
          sort: `${isAsc ? "" : "-"}user`,
        });
        break;
      default:
        fetchAllReports({
          sort: `${isAsc ? "" : "-"}${property}`,
        });
        break;
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const openModalDelete = (motion) => {
    setSelectedMotion(motion);
    setDel(true);
  };

  const closeModalDelete = () => {
    setSelectedMotion({
      date: "",
      userID: "",
      option: "",
      title: "",
      description: "",
      isApproved: "",
      isDeclined: "",
    });

    setDel(false);
  };

  const handleSearch = (keyword) => {
    setOrder("asc");
    setOrderBy("");
    setSerializer({
      ...serializer,
      firstName: {
        like: keyword,
      },
    });
  };

  return (
    <>
      <Grid container alignItems="center" className={styles.container}>
        <PageHeader
          title="Motion Reports"
          onSearch={handleSearch}
          csvReport={{
            headers,
            data: reportListExport,
            filename: "Motion Reports list.csv",
            fn: fetchActivityListExport,
          }}
        ></PageHeader>
        <Grid item xs={12}>
          <DataTable
            headers={headers}
            data={reportList}
            order={order}
            orderBy={orderBy}
            onDelete={openModalDelete}
            onRequestSort={handleRequestSort}
            onChangePage={(e, newPage) =>
              setSerializer({ ...serializer, page: newPage + 1 })
            }
            {...pagination}
          />
        </Grid>
      </Grid>

      <ModalDelete
        content="Motion"
        open={del}
        onCancel={closeModalDelete}
        onDelete={null}
      ></ModalDelete>

      <StyledModal open={open} onCancel={handleCloseModal}>
        <div className={styles.modal}>
          <IconButton
            size="small"
            onClick={handleCloseModal}
            className={styles.close}
          >
            <HighlightOff
              fontSize="large"
              style={{ color: "#320064" }}
            ></HighlightOff>
          </IconButton>
          <Typography variant="h4">{selectedData?.user?.fullName}</Typography>
          <Typography variant="subtitle1">Message</Typography>
          <Paper classes={{ root: styles.paper }}>
            <Typography variant="body2" classes={{ body2: styles.message }}>
              {selectedData?.description}
            </Typography>
          </Paper>
        </div>
      </StyledModal>
    </>
  );
};

export default MotionReport;
