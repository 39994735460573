import { server } from './index'

const deleteUserById = async (userId) => {
  try {
    const { data } = await server({
      method: 'DELETE',
      url: `/users/delete`,
      data: {
        userId
      }
    })
    return data
  } catch (error) {

  }
}

export default deleteUserById