import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { PageHeader, DataTable, ModalDelete } from "../../components";
import { getCommunityMembersByCode, kickCommunityMember } from "../../api";
import styles from "./CommunityMembers.module.css";

const headers = [
  { label: "Avatar", key: "avatar", type: "avatar" },
  { label: "Member Name", key: "fullName", type: "text" },
  { label: "User ID", key: "_id", type: "text" },
  { label: "Joined", key: "communityJoinDate", type: "date" },
  { label: "Access", key: "communityAdmin", type: "communityAdmin" },
  { label: "Total Score", key: "fullScore", type: "text" },
];

const CommunityMembers = () => {
  const history = useHistory();
  const params = useParams();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [open, setOpen] = useState(false);
  const [memberList, setMemberList] = useState(null);
  const [communityHasAdmin, setCommunityHasAdmin] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [pagination, setPagination] = useState({});
  const [serializer, setSerializer] = useState({});

  useEffect(() => {
    const fetchMembersByCode = async () => {
      try {
        setMemberList(null);
        const { code } = params;
        const {
          members: { docs, totalDocs, ...paginate },
          hasAdmin,
        } = await getCommunityMembersByCode(code, serializer);
        setCommunityHasAdmin(hasAdmin);
        setMemberList(docs);
        setPagination({
          ...paginate,
          totalDocs: totalDocs + 1,
        });
      } catch (error) {
        setMemberList([]);
      }
    };

    fetchMembersByCode();
    return () => {
      setMemberList([]);
    };
  }, [params, serializer]);

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (data) => {
    setSelectedMember(data);
    setOpen(true);
  };

  const handleDelete = async () => {
    try {
      await kickCommunityMember(selectedMember._id);
      setSerializer({});
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Grid container className={styles.container}>
        <PageHeader
          title="Community Members"
          onBack={history.goBack}
        ></PageHeader>
        <Grid item xs={12}>
          <DataTable
            headers={headers}
            data={memberList}
            communityHasAdmin={communityHasAdmin}
            order={order}
            orderBy={orderBy}
            onDelete={handleOpen}
            onRequestSort={handleRequestSort}
            onChangePage={(e, newPage) =>
              setSerializer({ ...serializer, page: newPage + 1 })
            }
            {...pagination}
          />
        </Grid>
      </Grid>

      <ModalDelete
        content="Member"
        open={open}
        onCancel={handleClose}
        onDelete={handleDelete}
        fullTitle={"Remove user"}
        fullMessage={
          "Are you sure you want to remove this user from the community?"
        }
      ></ModalDelete>
    </>
  );
};

export default CommunityMembers;
