

const dataNormalizer = (headers, raw) => {
  try {
    const nData = []
    const selectedHeaders = headers.filter(header => header.key.includes('.'))

    if (!selectedHeaders.length) {
      return { headers, data: raw }
    }

    const nHeaders = headers.reduce((a, b) => {
      b.key.includes('.')
        ? a.push({ ...b, key: b.label })
        : a.push(b)

      return a
    }, [])
    raw.forEach(data => {
      let value = {}

      selectedHeaders.forEach(nHead => {
        value[nHead.label] = nHead.key.split('.').reduce((a, b) => a[b] || '', data)
      });

      nData.push({
        ...data,
        ...value
      })
    });

    return { headers: nHeaders, data: nData }
  } catch (error) {
    return { headers, data: raw }
  }
}

export default dataNormalizer