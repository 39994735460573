import { server } from './index'
import Qs from 'qs'

const getCommunityActivities = async (id, params = {}, serializer) => {
  try {
    const { data } = await server({
      method: 'GET',
      url: `/activities/community/${id}`,
      params,
      paramsSerializer: (params) => {
        return Qs.stringify({
          ...params, ...serializer
        }, { 
          arrayFormat: 'brackets' 
        })
      }
    })
    return data
  } catch (error) {

  }
}

export default getCommunityActivities