import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import styles from "./CommunityRewards.module.css";
import { PageHeader, DataTable } from "../../components";

const createData = (image, name, status) => {
  return { image, name, status };
};

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0, 300),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3, 300),
  createData("Eclair", 262, 16.0, 24, 6.0, 300),
  createData("Cupcake", 305, 3.7, 67, 4.3, 300),
  createData("Gingerbread", 356, 16.0, 49, 3.9, 300),
];

const headers = [
  { label: "Reward Image", key: "image" },
  { label: "Rewards Name", key: "name" },
  { label: "Status", key: "status" },
];

const CommunityRewards = () => {
  const history = useHistory();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <>
      <Grid container className={styles.container}>
        <PageHeader
          title="Community Rewards"
          onBack={history.goBack}
        ></PageHeader>
        <Grid item xs={12}>
          <DataTable
            headers={headers}
            data={rows}
            order={order}
            isSpecial={true}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CommunityRewards;
