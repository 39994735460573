import { server } from "./index";
const resetEnergyModule = async (communityId) => {
  try {
    const { data } = await server({
      method: "PATCH",
      url: `/communities/resetEnergyModule`,
      data: {
        communityId,
      },
    });
    return data;
  } catch (error) {}
};
export default resetEnergyModule;
