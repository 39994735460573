import { server } from './index'

const kickCommunityMember = async (userId) => {
  try {
    const { data } = await server({
      method: 'POST',
      url: `/communities/kick`,
      data: {
        userId
      }
    })
    return data
  } catch (error) {

  }
}

export default kickCommunityMember