import { server } from './index'

const login = async ({ email, password }) => {
  try {
    const { data } = await server({
      method: 'POST',
      url: '/users/login',
      data: {
        email, password
      }
    })
    return data
  } catch (error) {

  }
}

export default login