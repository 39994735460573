import { server } from './index'

const deleteReportRewardById = async (rewardId) => {
  // console.log({rewardId});
  try {
    const { data } = await server({
      method: 'DELETE',
      url: `reports/rewards/${rewardId}`
    })

    // console.log({data});
    return data
  } catch (error) {

  }
}

export default deleteReportRewardById