import { server } from './index'

const forgotPassword = async ({ email }) => {
  try {
    const { data } = await server({
      method: 'POST',
      url: '/users/generateNewPassword',
      data: { 
        email,
        fromAdmin: true
      }
    })
    return data
  } catch (error) {

  }
}

export default forgotPassword