export default {
  'Appearance': {
    name: 'Stylist',
    label: 'Stylist',
    badge: require('./Talent_Stylist.png'),
  },
  'Carrying': {
    name: 'Bärhjälp',
    label: 'Bärhjälp',
    badge: require('./Talent_Carrying.png'),
  },
  'Childcare': {
    name: 'Barnomsorg',
    label: 'Barnomsorg',
    badge: require('./Talent_Childcare.png'),
  },
  'Cleaning': {
    name: 'Städning',
    label: 'Städning',
    badge: require('./Talent_Cleaning.png'),
  },
  'Economy': {
    name: 'Ekonom',
    label: 'Ekonom',
    badge: require('./Talent_Economy.png'),
  },
  'Everyday Hero': {
    name: 'Vardagshjälte',
    label: 'Vardagshjälte',
    badge: require('./Talent_Everyday_Hero.png'),
  },
  'Eye for Design': {
    name: 'Öga för design',
    label: 'Öga för design',
    badge: require('./Talent_Eye_For_Design.png'),
  },
  'Fixer': {
    name: 'Småfixare',
    label: 'Händig',
    badge: require('./Talent_Fixer.png'),
  },
  'Food': {
    name: 'Hobby kock',
    label: 'Hobby kock',
    badge: require('./Talent_Food.png'),
  },
  'Gaming': {
    name: 'Gaming',
    label: 'Gaming',
    badge: require('./Talent_Gaming.png'),
  },
  'Gardening': {
    name: 'Gröna fingrar',
    label: 'Gröna fingrar',
    badge: require('./Talent_Gardening.png'),
  },
  'Gym': {
    name: 'Gym',
    label: 'Gym',
    badge: require('./Talent_Gym.png'),
  },
  'Handy Man': {
    name: 'Hantverk',
    label: 'Hantverk',
    badge: require('./Talent_Handyman.png'),
  },
  'Health': {
    name: 'Hälsa',
    label: 'Hälsa',
    badge: require('./Talent_Health.png'),
  },
  'Home Fixer': {
    name: 'Hemmafixare',
    label: 'Hemmafixare',
    badge: require('./Talent_Home_Fixer.png'),
  },
  'Language': {
    name: 'Språkgeni',
    label: 'Språkgeni',
    badge: require('./Talent_Language.png'),
  },
  'Law and Insurance': {
    name: 'Juridik',
    label: 'Juridik',
    badge: require('./Talent_Law_And_Insurance.png'),
  },
  'Mindfulness': {
    name: 'Mindfulness',
    label: 'Mindfulness',
    badge: require('./Talent_Mindfulness.png'),
  },
  'Music and Art': {
    name: 'Musik',
    label: 'Musik',
    badge: require('./Talent_Music_And_Art.png'),
  },
  'Organizer': {
    name: 'Smart på förvaring',
    label: 'Smart på förvaring',
    badge: require('./Talent_Organizer.png'),
  },
  'Parenting': {
    name: 'Förälder',
    label: 'Förälder',
    badge: require('./Talent_Parenting.png'),
  },
  'Pet Care': {
    name: 'Djurvän',
    label: 'Djurvän',
    badge: require('./Talent_Pet_Care.png'),
  },
  'Repair': {
    name: 'Reparerare',
    label: 'Reparerare',
    badge: require('./Talent_Repair.png'),
  },
  'Running': {
    name: 'Löpare',
    label: 'Löpare',
    badge: require('./Talent_Running.png'),
  },
  'Security': {
    name: 'Säkerhet',
    label: 'Säkerhet',
    badge: require('./Talent_Security.png'),
  },
  'Media': {
    name: 'Media',
    label: 'Media',
    badge: require('./Talent_Social_Media.png'),
  },
  'Sporty': {
    name: 'Sportig',
    label: 'Sportig',
    badge: require('./Talent_Sporty.png'),
  },
  'Technical': {
    name: 'Teknisk',
    label: 'Teknisk',
    badge: require('./Talent_Technical.png'),
  },
  'Vehicles': {
    name: 'Vehicles',
    label: 'Vehicles',
    badge: require('./Talent_Vehicles.png'),
  },
  'Web and Network': {
    name: 'Nätverk',
    label: 'Nätverk',
    badge: require('./Talent_Web_And_Network.png'),
  },
  ///new skills
  'Environmental friend': {
    name: 'Miljömedveten',
    label: 'Miljömedveten',
    badge: require('./Environmental_Friend.png'),
  },
  'DIY': {
    name: 'DIY',
    label: 'DIY',
    badge: require('./DIY.png'),
  },
  'Board Games': {
    name: 'Sällskapsspel',
    label: 'Sällskapsspel',
    badge: require('./Board_Games.png'),
  },
  'Nature': {
    name: 'Natur',
    label: 'Natur',
    badge: require('./Nature.png'),
  },
  


};





