import React from 'react'
import {
  Grid,
  Paper,
  Typography
} from '@material-ui/core'
import styles from './OverviewCards.module.css'

const OverviewCards = ({count, text}) => {
  return (
    <Grid item xs={12} sm={4}>
      <Paper classes={{root: styles.cards}}>
        <Typography className={styles.count}>{count}</Typography>
        <Typography className={styles.text}>{text}</Typography>
      </Paper>
    </Grid>
  )
}

export default OverviewCards