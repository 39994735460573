import { server } from './index'

const updateUserById = async (id, updateFields) => {
  try {
    const { data } = await server({
      method: 'PATCH',
      url: `/users/${id}`,
      data: updateFields
    })
    return data
  } catch (error) {
    return error
  }
}

export default updateUserById