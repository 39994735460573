import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { PageHeader, DataTable, ModalDelete } from "../../components";
import { getCommunityActivities } from "../../api";
import styles from "./CommunityActivities.module.css";

const headers = [
  { label: "Created", key: "createdAt", type: "date" },
  { label: "Completed", key: "completed", type: "text" },
  { label: "Status", key: "status", type: "text" },
  { label: "Participants", key: "participants", type: "number" },
  { label: "Pending participants", key: "pendingParticipants", type: "number" },
  { label: "Community", key: "communityId.name", type: "text" },
  { label: "Category", key: "category", type: "text" },
  { label: "Sub Category", key: "subcategory", type: "text" },
  { label: "Activity Creator", key: "owner.fullName", type: "text" },
  { label: "Title", key: "name", type: "text" },
  { label: "Description", key: "description", type: "text" },
  { label: "Engagement Score", key: "communityScore" },
  { label: "Compensation", key: "compensation" },
  { label: "Date", key: "date", type: "number" },
];

const CommunityActivities = () => {
  const mountedRef = useRef(true);
  const history = useHistory();
  const params = useParams();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [open, setOpen] = useState(false);
  const [activityList, setActivityList] = useState(null);
  const [serializer, setSerializer] = useState({});
  const [pagination, setPagination] = useState({});

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchAllActivities = async () => {
      setActivityList(null);
      const { code } = params;
      const { docs, ...paginate } = await getCommunityActivities(
        code,
        serializer
      );
      if (mountedRef.current) {
        setActivityList(docs);
        setPagination(paginate);
      }
    };

    mountedRef.current && fetchAllActivities();
  }, [params, serializer]);

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleDelete = () => {
    // console.log('delete bos');
  };

  return (
    <>
      <Grid container className={styles.container}>
        <PageHeader
          title="Community Activities"
          onBack={history.goBack}
        ></PageHeader>
        <Grid item xs={12}>
          <DataTable
            headers={headers}
            data={activityList}
            order={order}
            orderBy={orderBy}
            onDelete={handleOpen}
            onRequestSort={handleRequestSort}
            onChangePage={(e, newPage) =>
              setSerializer({ ...serializer, page: newPage + 1 })
            }
            {...pagination}
          />
        </Grid>
      </Grid>

      <ModalDelete
        content="Activity"
        open={open}
        onCancel={handleClose}
        onDelete={handleDelete}
      ></ModalDelete>
    </>
  );
};

export default CommunityActivities;
