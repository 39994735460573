import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Drawer,
  Grid,
  List,
  ListItemIcon,
  ListItemText,
  ListItem,
  Button,
  Typography,
  Divider,
  Collapse,
} from "@material-ui/core";
import {
  HomeOutlined,
  HomeWorkOutlined,
  AccountCircleOutlined,
  AssignmentOutlined,
  FlagOutlined,
  PowerSettingsNew,
  EmojiEventsOutlined,
} from "@material-ui/icons";
import IconExpandLess from "@material-ui/icons/ExpandLess";
import IconExpandMore from "@material-ui/icons/ExpandMore";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import cx from "classnames";
import styles from "./Sidebar.module.css";
import {
  smallLogo,
  smallLogo2,
  Profile,
  Rewards,
  Reports,
  Community,
  Task,
  Home,
} from "../../assets";
import { useAuth } from "../../context/auth";

const Sidebar = () => {
  const location = useLocation();
  const selected = location.pathname.split("/")[1];
  const { setToken } = useAuth();

  const handleLogout = () => {
    setToken();
  };

  const [open, setOpen] = React.useState(true);

  function handleClick() {
    setOpen(!open);
  }

  return (
    <Drawer
      open={true}
      anchor="left"
      variant="persistent"
      classes={{ paper: styles.sidebar }}
    >
      <Grid container>
        <Grid item xs={12} classes={{ item: styles.logo }}>
          <img
            src={smallLogo2}
            alt="Local Minds Logo"
            style={{ width: "70px", resize: "contain" }}
          />
        </Grid>
        <Grid item xs={12}>
          <List component="nav">
            <Link to="/overview" style={{ textDecoration: "none" }}>
              <ListItem
                button
                selected={selected.includes("overview")}
                classes={{ selected: styles.selected }}
              >
                <ListItemIcon classes={{ root: styles.menuIcon }}>
                  <img
                    src={Home}
                    alt="Welcome Logo"
                    className={styles.iconSide}
                  />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: styles.menuText }}
                  primary={"Home"}
                ></ListItemText>
              </ListItem>
            </Link>
            <Link to="/communities" style={{ textDecoration: "none" }}>
              <ListItem
                button
                selected={selected.includes("communities")}
                classes={{ selected: styles.selected }}
              >
                <ListItemIcon classes={{ root: styles.menuIcon }}>
                  <img
                    src={Community}
                    alt="Welcome Logo"
                    className={styles.iconSide}
                  />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: styles.menuText }}
                  primary={"Community Management"}
                ></ListItemText>
              </ListItem>
            </Link>
            <Link to="/users" style={{ textDecoration: "none" }}>
              <ListItem
                button
                selected={selected.includes("users")}
                classes={{ selected: styles.selected }}
              >
                <ListItemIcon classes={{ root: styles.menuIcon }}>
                  <img
                    src={Profile}
                    alt="Welcome Logo"
                    className={styles.iconSide}
                  />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: styles.menuText }}
                  primary={"User Management"}
                ></ListItemText>
              </ListItem>
            </Link>
            <Link to="/rewards" style={{ textDecoration: "none" }}>
              <ListItem
                button
                selected={selected.includes("rewards")}
                classes={{ selected: styles.selected }}
              >
                <ListItemIcon classes={{ root: styles.menuIcon }}>
                  <img
                    src={Rewards}
                    alt="Welcome Logo"
                    className={styles.iconSide}
                  />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: styles.menuText }}
                  primary={"Reward Management"}
                ></ListItemText>
              </ListItem>
            </Link>
            <Link to="/activities" style={{ textDecoration: "none" }}>
              <ListItem
                button
                selected={selected.includes("activities")}
                classes={{ selected: styles.selected }}
              >
                <ListItemIcon classes={{ root: styles.menuIcon }}>
                  <img
                    src={Task}
                    alt="Welcome Logo"
                    className={styles.iconSide}
                  />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: styles.menuText }}
                  primary={"Activity Management"}
                ></ListItemText>
              </ListItem>
            </Link>
            <ListItem
              button
              onClick={handleClick}
              style={{
                textDecoration: "none",
                color: "white",
                fontSize: "1.2rem",
              }}
            >
              <ListItemIcon classes={{ root: styles.menuIcon }}>
                <img
                  src={Reports}
                  alt="Welcome Logo"
                  className={styles.iconSide}
                />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: styles.menuText }}
                primary={"Reports"}
              ></ListItemText>
              {open ? <IconExpandLess /> : <IconExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Divider />
              <List component="div" style={{ paddingLeft: "30px" }}>
                <Link to="/customerreports" style={{ textDecoration: "none" }}>
                  <ListItem
                    button
                    selected={selected.includes("customerreports")}
                    classes={{ selected: styles.selected }}
                  >
                    <ListItemIcon classes={{ root: styles.menuIcon }}>
                      <FiberManualRecordIcon></FiberManualRecordIcon>
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: styles.menuText }}
                      primary={"Customer Tickets"}
                    ></ListItemText>
                  </ListItem>
                </Link>
                <Link to="/reportsreward" style={{ textDecoration: "none" }}>
                  <ListItem
                    button
                    selected={selected.includes("reportsreward")}
                    classes={{ selected: styles.selected }}
                  >
                    <ListItemIcon classes={{ root: styles.menuIcon }}>
                      <FiberManualRecordIcon></FiberManualRecordIcon>
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: styles.menuText }}
                      primary={"Unlocked rewards"}
                    ></ListItemText>
                  </ListItem>
                </Link>
                <Link to="/motionreport" style={{ textDecoration: "none" }}>
                  <ListItem
                    button
                    selected={selected.includes("motionreport")}
                    classes={{ selected: styles.selected }}
                  >
                    <ListItemIcon classes={{ root: styles.menuIcon }}>
                      <FiberManualRecordIcon></FiberManualRecordIcon>
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: styles.menuText }}
                      primary={"Motion reports"}
                    ></ListItemText>
                  </ListItem>
                </Link>
              </List>
            </Collapse>
          </List>
        </Grid>
        <Grid item xs={12} classes={{ item: styles.logout }}>
          <Button
            onClick={handleLogout}
            startIcon={<PowerSettingsNew />}
            classes={{ label: cx(styles.menuText, styles.logoutBtn) }}
          >
            Log Out
          </Button>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default Sidebar;
