import { server } from "./index";

const updateAmbassador = async (id, objUpdate) => {
  try {
    const { data } = await server({
      method: "PATCH",
      url: `/communities/ambassador/${id}`,
      data: objUpdate,
    });
    return data;
  } catch (error) {}
};

export default updateAmbassador;
