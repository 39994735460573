import { server } from './index'

const deleteCommunityById = async (communityId) => {
  try {
    const { data } = await server({
      method: 'DELETE',
      url: `/communities`,
      data: {
        communityId
      }
    })
    return data
  } catch (error) {

  }
}

export default deleteCommunityById