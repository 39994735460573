import { server } from "./index";

const updateAssociatedCommunity = async (id, objUpdate) => {
  console.log(objUpdate);
  try {
    const { data } = await server({
      method: "PATCH",
      url: `/communities/associatedCommunity/${id}`,
      data: objUpdate,
    });
    return data;
  } catch (error) {}
};

export default updateAssociatedCommunity;
