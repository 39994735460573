import { server } from './index'

const getUserById = async ({ id }) => {
  try {
    const { data } = await server({
      method: 'GET',
      url: `/users/${id}`,
    })
    return data
  } catch (error) {

  }
}

export default getUserById