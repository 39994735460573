import React, { useState, useEffect, useRef } from "react";
import { Grid, IconButton, Typography, Paper } from "@material-ui/core";
import { HighlightOff } from "@material-ui/icons";
import { PageHeader, DataTable, StyledModal } from "../../components";
import { getReports, updateSentReport } from "../../api";
import styles from "./Reports.module.css";

const headers = [
  { label: "Category", key: "category" },
  { label: "Date", key: "createdAt", type: "date" },
  { label: "Reporter ID", key: "reporterId._id", type: "text" },
  { label: "User Name", key: "reporterId.fullName" },
  { label: "Content", key: "description", type: "text" },
  { label: "Community", key: "reporterId.communityId.name" },
  { label: "Email", key: "reporterId.email" },
  { label: "Reported User", key: "_id", type: "text" },
  { label: "Sent", key: "isSent", type: "isSent" },
];

const limitData = 100;

const Reports = () => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [open, setOpen] = useState(false);
  const [reportList, setReportList] = useState(null);
  const [reportListExport, setReportListExport] = useState(null);
  const [selectedData, setSelectedData] = useState({});
  const [serializer, setSerializer] = useState({});
  const [pagination, setPagination] = useState({});
  const mountedRef = useRef(true);

  const [selectedReport, setSelectedReport] = useState({
    reason: "",
    reporterId: "",
    description: "",
    category: "",
    isSent: "",
  });

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    mountedRef.current && fetchAllReports({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serializer, selectedReport]);

  const fetchAllReports = async (params) => {
    try {
      setReportList(null);
      const {
        reports: { docs, ...paginate },
      } = await getReports({ ...params, limit: limitData }, serializer);
      if (mountedRef.current) {
        setReportList(docs);
        setPagination(paginate);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchActivityListExport = async (params) => {
    try {
      if (!reportListExport) {
        const limit = 999999999;
        const {
          reports: { docs },
        } = await getReports({ ...params, limit }, serializer);
        setReportListExport(docs);

        return docs;
      }

      return reportListExport;
    } catch (error) {
      mountedRef.current && setReportListExport(null);
    }
  };

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    switch (property) {
      case "reporterId.fullName":
      case "reporterId.email":
      case "reporterId.communityId.name":
        fetchAllReports({
          sort: `${isAsc ? "" : "-"}reporterId`,
        });
        break;
      default:
        fetchAllReports({
          sort: `${isAsc ? "" : "-"}${property}`,
        });
        break;
    }
  };

  const handleRequestSent = async (data) => {
    setSelectedReport(data);
    // console.log('handleRequest', data);
    try {
      await updateSentReport(data._id);
      // setDel(false)
    } catch (e) {
      // setDel(false)
      console.log(e);
    }
    setSelectedReport({
      reason: "",
      reporterId: "",
      description: "",
      category: "",
      isSent: "",
    });
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleShowInfo = (data) => {
    setSelectedData(data);
    handleOpenModal();
  };

  const handleSearch = (keyword) => {
    setOrder("asc");
    setOrderBy("");
    setSerializer({
      ...serializer,
      firstName: {
        like: keyword,
      },
    });
  };

  return (
    <>
      <Grid container alignItems="center" className={styles.container}>
        <PageHeader
          title="Reports"
          onSearch={handleSearch}
          csvReport={{
            headers,
            data: reportListExport,
            filename: "Report List.csv",
            fn: fetchActivityListExport,
          }}
        ></PageHeader>
        <Grid item xs={12}>
          <DataTable
            headers={headers}
            data={reportList}
            order={order}
            orderBy={orderBy}
            onDelete={handleShowInfo}
            onSent={handleRequestSent}
            onRequestSort={handleRequestSort}
            onChangePage={(e, newPage) =>
              setSerializer({ ...serializer, page: newPage + 1 })
            }
            {...pagination}
          />
        </Grid>
      </Grid>

      <StyledModal open={open} onCancel={handleCloseModal}>
        <div className={styles.modal}>
          <IconButton
            size="small"
            onClick={handleCloseModal}
            className={styles.close}
          >
            <HighlightOff
              fontSize="large"
              style={{ color: "#320064" }}
            ></HighlightOff>
          </IconButton>
          <Typography variant="h4">
            {selectedData?.reporterId?.fullName}
          </Typography>
          <Typography variant="subtitle1">Message</Typography>
          <Paper classes={{ root: styles.paper }}>
            <Typography variant="body2" classes={{ body2: styles.message }}>
              {selectedData?.description}
            </Typography>
          </Paper>
        </div>
      </StyledModal>
    </>
  );
};

export default Reports;
