import { server } from './index'
const splitPoints = async (communityId) => {
  try {
    const { data } = await server({
      method: 'PATCH',
      url: `/communities`,
      data: {
         communityId
       }
    })
    return data
  } catch (error) {
  }
}
export default splitPoints

