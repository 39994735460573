import React from 'react'
import cx from 'classnames'
import {
  Typography,
  Button,
} from '@material-ui/core'
import StyledModal from '../StyledModal/StyledModal'
import styles from './ModalDelete.module.css'

const ModalDelete = (props) => {
  const {
    content,
    onCancel,
    onDelete,
    fullTitle,
    fullMessage 
  } = props
  
  return (
    <StyledModal {...props}>
      <div className={styles.container}>
        <Typography variant="h4">
          {fullTitle || `Delete ${content}`}
        </Typography>
        <Typography variant="subtitle2" style={{margin: '16px auto'}}>
          {fullMessage || `Are you sure you want to delete this ${content.toLowerCase()}?`}
        </Typography>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <Button variant="contained" className={cx(styles.btn, styles.cancel)} onClick={onCancel}>Cancel</Button>
          <Button variant="contained" className={cx(styles.btn, styles.delete)} onClick={onDelete}>Delete</Button>
        </div>
      </div>
    </StyledModal>
  )
}

export default ModalDelete