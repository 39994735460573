import { server } from './index'

const login = async () => {
  try {
    const { data } = await server({
      method: 'GET',
      url: '/overview',
    })
    return data
  } catch (error) {

  }
}

export default login