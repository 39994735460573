import { server } from "./index";
const communityExit = async (communityId) => {
  try {
    const { data } = await server({
      method: "PATCH",
      url: `/communities/communityExit`,
      data: {
        communityId,
      },
    });
    return data;
  } catch (error) {}
};
export default communityExit;
