import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Grid, CircularProgress, Typography } from '@material-ui/core'
import { PageHeader, SkillCards } from '../../components'
import { getCommunitySkillsByCode } from '../../api'
import styles from './InternalSkills.module.css'

const InternalSkills = () => {
  const history = useHistory()
  const params = useParams()
  const [skillList, setSkillList] = useState([])
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    const fetchSkillsByCode = async () => {
      setLoading(true)
      try {
        const { code } = params
        const { internalSkills } = await getCommunitySkillsByCode({ code })
        setSkillList(internalSkills)
      } catch (error) {
        setSkillList([])
      }
      setLoading(false)
    }

    fetchSkillsByCode()
    return () => {
      setSkillList([])
      setLoading(true)
    }
  }, [params])

  return (
    isLoading
    ? (
      <Grid container justify="center" style={{ marginTop: "30%" }}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <CircularProgress></CircularProgress>
          <Typography variant="h6">Loading...</Typography>
        </Grid>
      </Grid>
    )
    : (
      <Grid container spacing={3} className={styles.container}>
        <PageHeader
          title="Internal Skills"
          onBack={history.goBack}
        ></PageHeader>
        {
          skillList.map((skill, index) => (
            <Grid item xs={2} key={index} style={{textAlign: 'center'}}>
              <SkillCards skill={skill}></SkillCards>
            </Grid>
          ))
        }
      </Grid>
    )
  )
}

export default InternalSkills