import { server } from "./index";

const updateSentReport = async (id) => {
  try {
    const { data } = await server({
      method: "PUT",
      url: `/reports/reports/${id}/sent`,
      data: {},
    });
    return data;
  } catch (error) {
    return error;
  }
};

export default updateSentReport;
