import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import styles from "./Login.module.css";
import { smallLogo, welcomeLogo } from "../../assets";
import { login } from "../../api";
import { useAuth } from "../../context/auth";
import { forgotPassword } from "../../api";
import userTypes from "../../contants/userTypes";
import swal from "sweetalert";
import { server } from "../../api";

const Login = () => {
  const { setToken, authToken } = useAuth();
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    try {
      e.preventDefault();
      setErrorMessage("");
      const { token, user, error } = await login({ email, password });
      if (token && user.userType === userTypes.administrator) {
        setToken(token);
        server.defaults.headers.common["Authorization"] = token;
      } else {
        throw error;
      }
    } catch (error) {
      setPassword("");
      setErrorMessage("Incorrect email or password!");
    }
  };

  const handleForgotPassword = async (e) => {
    setLoading(true);
    try {
      e.preventDefault();
      setErrorMessage("");
      if (!email) {
        throw e;
      }

      const { title, message, error } = await forgotPassword({ email });
      if (error) {
        throw error;
      } else {
        swal(title, message);
        setEmail("");
        setPassword("");
      }
    } catch (error) {
      setErrorMessage("Email not registered!");
    }
    setLoading(false);
  };

  if (authToken) {
    return <Redirect to="/overview" />;
  }

  return (
    <Grid container style={{ minHeight: "100vh" }}>
      <Grid item xs={12} className={styles.header}>
        <img src={smallLogo} alt="Local Minds Logo" className={styles.logo} />
      </Grid>
      <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
        <img
          src={welcomeLogo}
          alt="Welcome Logo"
          className={styles.welcomeLogo}
        />
      </Grid>
      <Grid item xs={12} md={6} className={styles.rightSide}>
        <h1 className={styles.adminTitle}>Admin Login</h1>
        <form
          noValidate
          autoComplete="off"
          className={styles.form}
          onSubmit={handleLogin}
        >
          <TextField
            disabled={isLoading}
            type="username"
            label="email"
            variant="outlined"
            fullWidth
            InputProps={{ className: styles.fields }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            disabled={isLoading}
            label="password"
            variant="outlined"
            fullWidth
            InputProps={{ className: styles.fields }}
            type="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Grid container alignItems="center" style={{ textAlign: "center" }}>
            {errorMessage && (
              <Grid item xs={12}>
                <Typography variant="body1" style={{ color: "red" }}>
                  {errorMessage}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                type="submit"
                className={styles.loginBtn}
              >
                Login
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              {isLoading ? (
                <>
                  <CircularProgress size={20}></CircularProgress>
                  <Typography className={styles.forgotLink}>
                    Sending new Password...
                  </Typography>
                </>
              ) : (
                <Button onClick={handleForgotPassword}>
                  <Typography className={styles.forgotLink}>
                    Forgot Password?
                  </Typography>
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Grid item xs={12} className={styles.footer}>
        <Typography className={styles.copyright}>
          Developed by Local Minds AB
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Login;
