/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@material-ui/core";
import { PageHeader, DataTable, ModalDelete } from "../../components";
import { getActivities, deleteActivityById } from "../../api";
import styles from "./Activities.module.css";

const headers = [
  { label: "Created", key: "createdAt", type: "date" },
  { label: "Completed", key: "completed", type: "text" },
  { label: "Status", key: "status", type: "text" },
  { label: "Community", key: "communityId.name", type: "text" },
  { label: "Activity Creator", key: "owner.fullName", type: "text" },
  { label: "Participants", key: "participants", type: "number" },
  { label: "Participants ID", key: "activeParticipants", type: "text" },
  { label: "Pending participants", key: "pendingParticipants", type: "number" },
  { label: "Pending ID", key: "pendingParticipantsId", type: "text" },
  { label: "Title", key: "name", type: "text" },
  { label: "Description", key: "description", type: "text" },
  { label: "Category", key: "category", type: "text" },
  { label: "Sub Category", key: "subcategory", type: "text" },
  { label: "Engagement Score", key: "communityScore", type: "text" },
  { label: "Compensation", key: "compensation", type: "text" },
  { label: "Date", key: "date", type: "number", type: "text" },
  { label: "Activity ID", key: "_id" },
];

const limitData = 100;

const Activities = () => {
  const mountedRef = useRef(true);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("createdAt");
  const [activityList, setActivityList] = useState(null);
  const [activityListExport, setActivityListExport] = useState(null);
  const [pagination, setPagination] = useState({});
  const [serializer, setSerializer] = useState({});
  const [open, setOpen] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState({});

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    mountedRef.current && fetchAllActivities({});
  }, [serializer]);

  const fetchAllActivities = async (params) => {
    try {
      setActivityList(null);
      const {
        activities: { docs, ...paginate },
      } = await getActivities({ ...params, limit: limitData }, serializer);
      const data = docs.map((doc) => ({
        ...doc,
        status: doc.isActive ? "Active" : "Inactive",
        participants: `${doc.activeParticipants.length}/${doc.totalSlot}`,
        pendingParticipants: `${doc.pendingParticipants.length}`,
        pendingParticipantsId: `${doc.pendingParticipants}`,
        completed: doc.isCompleted ? "True" : "False",
      }));

      if (mountedRef.current) {
        setActivityList(data);
        setPagination(paginate);
      }
    } catch (error) {
      setActivityList(null);
    }
  };

  const fetchActivityListExport = async (params) => {
    try {
      if (!activityListExport) {
        const limit = 999999999;
        const {
          activities: { docs },
        } = await getActivities({ ...params, limit }, serializer);
        setActivityListExport(docs);

        return docs;
      }

      return activityListExport;
    } catch (error) {
      mountedRef.current && setActivityListExport(null);
    }
  };

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    fetchAllActivities({
      sort: `${!isAsc ? "-" : ""}${property}`,
    });
  };

  const handleSearch = (keyword) => {
    setOrder("asc");
    setOrderBy("");
    setSerializer({
      name: {
        like: keyword,
      },
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (activity) => {
    setSelectedActivity(activity);
    setOpen(true);
  };

  const handleDelete = async () => {
    try {
      await deleteActivityById(selectedActivity._id, {
        communityId: selectedActivity.communityId.id,
      });

      await deleteActivityById(selectedActivity._id);
    } catch (err) {
      // do something
      console.log(err);
    }
    setSelectedActivity({});
    setOpen(false);
  };

  return (
    <>
      <Grid container alignItems="center" className={styles.container}>
        <PageHeader
          title="Activity Management"
          onSearch={handleSearch}
          csvReport={{
            headers,
            data: activityListExport,
            filename: "Activity List.csv",
            fn: fetchActivityListExport,
          }}
        ></PageHeader>
        <Grid item xs={13}>
          <DataTable
            headers={headers}
            data={activityList}
            total
            order={order}
            orderBy={orderBy}
            onDelete={handleOpen}
            onRequestSort={handleRequestSort}
            onChangePage={(e, newPage) =>
              setSerializer({ ...serializer, page: newPage + 1 })
            }
            {...pagination}
          />
        </Grid>
      </Grid>
      <ModalDelete
        content="Activity"
        open={open}
        onCancel={handleClose}
        onDelete={handleDelete}
      ></ModalDelete>
    </>
  );
};

export default Activities;
