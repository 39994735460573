import { server } from './index'

const upload = async ({ image }) => {
  try {
    const formData = new FormData()
    await formData.append('image', image)
    const { data } = await server({
      method: 'POST',
      url: '/upload',
      data: formData,
    })
    return data
  } catch (error) {

  }
}

export default upload