import React, { useState, useEffect, useRef } from "react";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { OverviewCards } from "../../components";
import { overview } from "../../api";
import styles from "./Overview.module.css";
import { welcomeProfiles, welcomeRocket } from "../../assets";

const Overview = () => {
  const [data, setData] = useState([]);
  const mountedRef = useRef(true);

  useEffect(() => {
    const fetchOverview = async () => {
      const data = await overview();
      mountedRef.current && setData(data?.data);
    };

    fetchOverview();
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <Grid
      container
      style={{
        marginTop: "20px",
        flexDirection: "column",
        alignItems: "center",
      }}
      spacing={4}
    >
      <Grid
        item
        xs={5}
        md={6}
        style={{
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <img
          src={welcomeRocket}
          alt="Welcome Logo"
          className={styles.welcomeRocket}
        />
      </Grid>
      <Grid item xs={5}>
        <Typography variant="h4" align="center" classes={{ h4: styles.title }}>
          Happy to have you here!
        </Typography>
      </Grid>
      {!data.length ? (
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <CircularProgress></CircularProgress>
          <Typography variant="h6">Loading...</Typography>
        </Grid>
      ) : (
        data.map((value) => (
          <OverviewCards
            count={value.count}
            text={value.text}
            key={value.text}
          ></OverviewCards>
        ))
      )}
      <Grid
        item
        xs={12}
        md={6}
        style={{
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <img
          src={welcomeProfiles}
          alt="Welcome Logo"
          className={styles.welcomeProfiles}
        />
      </Grid>
    </Grid>
  );
};

export default Overview;
