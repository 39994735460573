import React from 'react'
import { Badge, Paper, Typography } from '@material-ui/core'
import skills from '../../assets/skills'
import styles from './SkillCards.module.css'

const SkillCards = ({ skill }) => {
  // console.log(skill);
  return (
    <>
      <Badge
        showZero
        badgeContent={skill.count} 
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        color="primary"
        classes={{badge: styles.badge}}
      >
        <Paper className={styles.skill}>
          {
            skills[skill._id] &&
            <img
              src={skills[skill._id].badge}
              alt={`${skill.label} badge`}
              style={{ width: '35px', height: '35px' }}
            />
          }
        </Paper>
      </Badge>
      <Typography variant="body1" className={styles.label}>{skills[skill._id].label}</Typography>
    </>
  )
}

export default SkillCards