import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  IconButton,
  Typography,
  FormControl,
  OutlinedInput,
  Button,
  Badge,
  Avatar,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import { HighlightOff } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { pencil } from "../../assets";
import cx from "classnames";
import { PageHeader, DataTable, StyledModal } from "../../components";
import { getAllCommunities, createNewCommunity, upload } from "../../api";
import styles from "./Communities.module.css";

const headers = [
  { label: "Community Name", key: "name" },
  { label: "Community Code", key: "code" },
  { label: "Community ID", key: "_id" },
  { label: "Community Score", key: "score" },
  { label: "Members", key: "totalMembers" },
  { label: "Total Activities", key: "totalActivities" },
  { label: "Disposable Points", key: "disposableEngagementPoints" },
];

const fields = [
  { key: "name", type: "text", label: "Community Name" },
  { key: "address", type: "text", label: "Address" },
  { key: "code", type: "number", label: "Activation Code" },
  {
    key: "disposableEngagementPoints",
    type: "number",
    label: "Disposable Engagement Points",
  },
];

const Communities = () => {
  const history = useHistory();
  const file = useRef();
  const mountedRef = useRef(true);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [open, setOpen] = useState(false);
  const [errord, setErrord] = useState(false);
  const [communityList, setCommunityList] = useState(null);
  const [communityListExport, setCommunityListExport] = useState(null);
  const [newCommunity, setNewCommunity] = useState({
    avatar: "https://via.placeholder.com/300",
    name: "",
    address: "",
    code: 0,
    disposableEngagementPoints: 0,
  });
  const [serializer, setSerializer] = useState({});
  const [pagination, setPagination] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    const uploadFile = async () => {
      setLoading(true);
      try {
        const { url: avatar } = await upload({ image: selectedFile });
        setNewCommunity({
          ...newCommunity,
          avatar,
        });
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    selectedFile && mountedRef.current && uploadFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  useEffect(() => {
    mountedRef.current && fetchCommunityList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serializer]);

  const fetchCommunityList = async (params) => {
    try {
      setCommunityList(null);
      const {
        communities: { docs, ...paginate },
      } = await getAllCommunities(params, serializer);
      if (mountedRef.current) {
        setCommunityList(docs);
        setPagination(paginate);
      }
    } catch (error) {
      mountedRef.current && setCommunityList(null);
    }
  };

  const fetchCommunityListExport = async (params, reset = false) => {
    try {
      if (!communityListExport || reset) {
        const limit = 999999999;
        const {
          communities: { docs },
        } = await getAllCommunities({ ...params, limit }, serializer);
        setCommunityListExport(docs);

        return docs;
      }

      return communityListExport;
    } catch (error) {
      mountedRef.current && setCommunityListExport(null);
    }
  };

  const handleOpenModal = () => {
    setNewCommunity({});
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleCreateCommunity = async () => {
    setLoading(true);
    let createCommunity;
    try {
      console.log("konto");
      createCommunity = await createNewCommunity(newCommunity);
      await fetchCommunityList();
      fetchCommunityListExport({}, true);
    } catch (error) {
      console.log(error.error.message);
      setLoading(false);
    }
    setLoading(false);
    if (!!!createCommunity) {
      console.log("Failed to create community !");
      setErrord(true);
    }
    setTimeout(handleCloseModal, 3000);
    setTimeout(() => {
      setErrord(false);
    }, 3000);
  };

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    fetchCommunityList({
      sort: `${isAsc ? "" : "-"}${property}`,
    });
  };

  const handleInfoPage = (data) => {
    history.push(`/communities/${data.code}`);
  };

  const handleSearch = (keyword) => {
    setOrder("asc");
    setOrderBy("");
    setSerializer({
      ...serializer,
      name: {
        like: keyword,
      },
    });
  };

  const handleChangeFields = async (e) => {
    if (!(e.target.name === "code" && e.target.value.length > 5)) {
      setNewCommunity({
        ...newCommunity,
        [e.target.name]: e.target.value,
      });
    }
  };

  return (
    <>
      <Grid container alignItems="center" className={styles.container}>
        <PageHeader
          title="Community Management"
          onAddNew={handleOpenModal}
          onSearch={handleSearch}
          csvReport={{
            headers,
            data: communityListExport,
            filename: "Community List.csv",
            fn: fetchCommunityListExport,
          }}
        ></PageHeader>
        <Grid item xs={12}>
          <DataTable
            headers={headers}
            data={communityList}
            order={order}
            orderBy={orderBy}
            onInfo={handleInfoPage}
            onRequestSort={handleRequestSort}
            onChangePage={(e, newPage) =>
              setSerializer({ ...serializer, page: newPage + 1 })
            }
            {...pagination}
          />
        </Grid>
      </Grid>

      <StyledModal open={open} onCancel={handleCloseModal}>
        <div className={styles.modal}>
          {isLoading ? (
            <>
              <CircularProgress style={{ marginTop: "25px" }} />
              <Typography className={cx(styles.label, styles.alignLeft)}>
                Please wait...
              </Typography>
            </>
          ) : errord ? (
            <Typography
              style={{
                alignText: "center",
                marginTop: "25px",
                paddingTop: "3%",
              }}
            >
              Failed to create community !
            </Typography>
          ) : (
            <>
              <IconButton
                size="small"
                onClick={handleCloseModal}
                className={styles.close}
              >
                <HighlightOff
                  fontSize="large"
                  style={{ color: "#320064" }}
                ></HighlightOff>
              </IconButton>
              <Badge
                overlap="circle"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                badgeContent={
                  <IconButton
                    className={styles.edit}
                    onClick={() => file.current.click()}
                    size="small"
                  >
                    <Avatar
                      src={pencil}
                      alt="pencil"
                      style={{ height: "30px", width: "30px" }}
                    />
                  </IconButton>
                }
                style={{ marginBottom: "30px" }}
              >
                <Avatar
                  alt="Community Avatar"
                  src={`http://i0.wp.com/${
                    newCommunity?.avatar?.slice(8) || "via.placeholder.com/300"
                  }?w=300`}
                  className={styles.avatar}
                />
              </Badge>
              <input
                type="file"
                id="file"
                ref={file}
                style={{ display: "none" }}
                onChange={(e) => setSelectedFile(e.target.files[0])}
              />
              {fields.map((field) => (
                <Grid
                  item
                  xs={12}
                  key={field.key}
                  style={{ marginBottom: "10px", padding: "0 25px" }}
                >
                  <Typography className={cx(styles.label, styles.alignLeft)}>
                    {field.label}
                  </Typography>
                  <FormControl variant="outlined" fullWidth>
                    <OutlinedInput
                      type={field.type}
                      placeholder={field.label}
                      className={styles.fields}
                      value={newCommunity[field.key] || ""}
                      name={field.key}
                      onChange={handleChangeFields}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            className={styles.edit}
                            size="small"
                            disabled
                          >
                            <Avatar
                              src={pencil}
                              alt="pencil"
                              style={{ height: "20px", width: "20px" }}
                            />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
              ))}
              <Button
                variant="contained"
                classes={{
                  root: cx(styles.btn, styles.create),
                  label: styles.btnLabel,
                }}
                onClick={handleCreateCommunity}
              >
                Create
              </Button>
            </>
          )}
        </div>
      </StyledModal>
    </>
  );
};

export default Communities;
