import { server } from "./index";

const deleteActivityById = async (activityId, objUpdate) => {
  console.log(objUpdate);
  try {
    const { data } = await server({
      method: "DELETE",
      url: `/activities/${activityId}`,
      data: objUpdate,
    });
    return data;
  } catch (error) {}
};
export default deleteActivityById;
