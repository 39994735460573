import React from "react";
import cx from "classnames";
import { Typography, Button } from "@material-ui/core";
import StyledModal from "../StyledModal/StyledModal";
import styles from "./ModalExit.module.css";

const ModalExit = (props) => {
  const { content, onCancel, onExit, fullTitle, fullMessage } = props;

  return (
    <StyledModal {...props}>
      <div className={styles.container}>
        <Typography variant="h4">{fullTitle || `Close community`}</Typography>
        <Typography variant="subtitle2" style={{ margin: "16px auto" }}>
          {fullMessage || `Are you sure you want close down this community?`}
        </Typography>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            className={cx(styles.btn, styles.cancel)}
            onClick={onCancel}
          >
            Abort
          </Button>
          <Button
            variant="contained"
            className={cx(styles.btn, styles.split)}
            onClick={onExit}
          >
            Exit
          </Button>
        </div>
      </div>
    </StyledModal>
  );
};

export default ModalExit;
