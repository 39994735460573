import { server } from './index'

const deleteCommunityById = async (rewardId) => {
  try {
    const { data } = await server({
      method: 'DELETE',
      url: `/rewards/${rewardId}`
    })
    return data
  } catch (error) {

  }
}

export default deleteCommunityById