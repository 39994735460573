import React from 'react'
import {
  Modal,
  Fade,
  Backdrop
} from '@material-ui/core'
import styles from './StyledModal.module.css'

const StyledModal = ({ open, onCancel, children: Component }) => {
  return (
    <Modal
      className={styles.modal}
      open={open}
      onClose={onCancel}
      closeAfterTransition
      disableBackdropClick
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        {Component}
      </Fade>
    </Modal>
  )
}

export default StyledModal