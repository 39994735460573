import { server } from './index'
import Qs from 'qs'

const getAllRewards = async (params = {}, serializer) => {
  try {
    const { data } = await server({
      method: 'GET',
      url: '/rewards',
      params,
      paramsSerializer: (params) => {
        return Qs.stringify({
          pagination: true,
          ...params, 
          ...serializer
        }, { 
          arrayFormat: 'brackets' 
        })
      }
    })
    return data
  } catch (error) {

  }
}

export default getAllRewards