import { server } from './index'
import Qs from 'qs'

const getCommunityMembersByCode = async (code, serializer) => {
  try {
    const { data } = await server({
      method: 'GET',
      url: `/communities/members/${code}`,
      params: {},
      paramsSerializer: (params) => {
        return Qs.stringify({
          ...params, ...serializer  
        }, { 
          arrayFormat: 'brackets' 
        })
      }
    })
    return data
  } catch (error) {

  }
}

export default getCommunityMembersByCode