import { server } from "./index";
import Qs from "qs";

const getReportReward = async (params = {}, serializer) => {
  try {
    const { data } = await server({
      method: "GET",
      url: "/reports/rewards",
      params,
      paramsSerializer: (params) => {
        return Qs.stringify(
          {
            ...params,
            ...serializer,
          },
          {
            arrayFormat: "brackets",
          }
        );
      },
    });
    return data;
  } catch (error) {}
};

export default getReportReward;
