/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { Grid, IconButton, Typography, Paper } from "@material-ui/core";
import { HighlightOff } from "@material-ui/icons";
import {
  PageHeader,
  DataTable,
  StyledModal,
  ModalDelete,
} from "../../components";
import {
  getReportReward,
  deleteReportRewardById,
  updateSentRewardById,
} from "../../api";
import styles from "./Reports.module.css";

const headers = [
  { label: "User Name", key: "user.fullName" },
  { label: "User ID", key: "_id", type: "text" },
  { label: "Date", key: "createdAt", type: "date" },
  { label: "Reward", key: "reward.title" },
  { label: "Community", key: "user.communityId.name" },

  { label: "Delivery Mail", key: "rewardEmail" },
  { label: "Delivery Address", key: "deliveryAddress" },
  { label: "Email", key: "user.email" },
  { label: "Sent", key: "isSent", type: "isSent" },
];

const limitData = 100;

const ReportsReward = () => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [open, setOpen] = useState(false);
  const [reportList, setReportList] = useState(null);
  const [reportListExport, setReportListExport] = useState(null);
  const [selectedData, setSelectedData] = useState({});
  const [serializer, setSerializer] = useState({});
  const [pagination, setPagination] = useState({});
  const mountedRef = useRef(true);
  const [del, setDel] = useState(false);
  const [selectedReward, setSelectedReward] = useState({
    name: "",
    date: "",
    reward: "",
    email: "",
    community: "",
    isSent: "",
  });

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    mountedRef.current && fetchAllReports({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serializer, del, selectedReward]);

  const fetchAllReports = async (params) => {
    try {
      setReportList(null);
      const { docs, ...paginate } = await getReportReward(
        { ...params, limit: limitData },
        serializer
      );
      if (mountedRef.current) {
        setReportList(docs);
        setPagination(paginate);
      }
      console.log(docs);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchActivityListExport = async (params) => {
    try {
      if (!reportListExport) {
        const limit = 999999999;
        const {
          reports: { docs },
        } = await getReportReward({ ...params, limit }, serializer);
        setReportListExport(docs);

        return docs;
      }

      return reportListExport;
    } catch (error) {
      mountedRef.current && setReportListExport(null);
    }
  };

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    switch (property) {
      case "user.fullName":
      case "user.email":
      case "user.communityId.name":
        fetchAllReports({
          sort: `${isAsc ? "" : "-"}user`,
        });
        break;
      default:
        fetchAllReports({
          sort: `${isAsc ? "" : "-"}${property}`,
        });
        break;
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleRequestSent = async (data) => {
    setSelectedReward(data);
    // console.log('handleRequest', data);
    try {
      await updateSentRewardById(data._id);
      // setDel(false)
    } catch (e) {
      // setDel(false)
      console.log(e);
    }
    setSelectedReward({
      name: "",
      date: "",
      reward: "",
      email: "",
      community: "",
      isSent: "",
    });
  };

  const openModalDelete = (reward) => {
    setSelectedReward(reward);
    setDel(true);
  };

  const closeModalDelete = () => {
    setSelectedReward({
      name: "",
      date: "",
      reward: "",
      email: "",
      community: "",
      isSent: "",
    });

    setDel(false);
  };

  const handleDelete = async () => {
    try {
      await deleteReportRewardById(selectedReward._id);
    } catch (err) {
      // do something
      console.log(err);
    }
    setSelectedReward({
      name: "",
      date: "",
      reward: "",
      email: "",
      community: "",
      isSent: "",
    });
    setDel(false);
  };

  const handleSearch = (keyword) => {
    setOrder("asc");
    setOrderBy("");
    setSerializer({
      ...serializer,
      firstName: {
        like: keyword,
      },
    });
  };

  return (
    <>
      <Grid container alignItems="center" className={styles.container}>
        <PageHeader
          title="Report rewards"
          onSearch={handleSearch}
          csvReport={{
            headers,
            data: reportListExport,
            filename: "Report rewards list.csv",
            fn: fetchActivityListExport,
          }}
        ></PageHeader>
        <Grid item xs={12}>
          <DataTable
            headers={headers}
            data={reportList}
            order={order}
            orderBy={orderBy}
            onDelete={openModalDelete}
            onRequestSort={handleRequestSort}
            onSent={handleRequestSent}
            onChangePage={(e, newPage) =>
              setSerializer({ ...serializer, page: newPage + 1 })
            }
            {...pagination}
          />
        </Grid>
      </Grid>

      <ModalDelete
        content="Reward"
        open={del}
        onCancel={closeModalDelete}
        onDelete={handleDelete}
      ></ModalDelete>

      <StyledModal open={open} onCancel={handleCloseModal}>
        <div className={styles.modal}>
          <IconButton
            size="small"
            onClick={handleCloseModal}
            className={styles.close}
          >
            <HighlightOff
              fontSize="large"
              style={{ color: "#320064" }}
            ></HighlightOff>
          </IconButton>
          <Typography variant="h4">{selectedData?.user?.fullName}</Typography>
          <Typography variant="subtitle1">Message</Typography>
          <Paper classes={{ root: styles.paper }}>
            <Typography variant="body2" classes={{ body2: styles.message }}>
              {selectedData?.description}
            </Typography>
          </Paper>
        </div>
      </StyledModal>
    </>
  );
};

export default ReportsReward;
