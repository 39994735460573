import { server } from './index'

const createNewReward = async ({
  icon = 'https://via.placeholder.com/300',
  title,
  score,
  description,
  descriptionEnglish,
  category = 'global',
  isActive = true,
}) => {
  try {
    const { data } = await server({
      method: 'POST',
      url: '/rewards',
      data: {
        icon,
        title,
        score,
        description,
        descriptionEnglish,
        category,
        isActive,
      }
    })
    return data
  } catch (error) {

  }
}

export default createNewReward