import { server } from './index'

const createNewCommunity = async ({
  avatar = 'https://via.placeholder.com/300',
  name,
  address,
  adminEmail,
  code,
  disposableEngagementPoints 
}) => {
  try {
    const { data } = await server({
      method: 'POST',
      url: '/communities',
      data: {
        avatar,
        name,
        address,
        email: adminEmail,
        code,
        disposableEngagementPoints
      }
    })
    return data
  } catch (error) {

  }
}

export default createNewCommunity