import React from 'react'
import cx from 'classnames'
import {
  Typography,
  Button,
} from '@material-ui/core'
import StyledModal from '../StyledModal/StyledModal'
import styles from './ModalSplit.module.css'

const ModalSplit = (props) => {
  const {
    content,
    onCancel,
    onSplit,
    fullTitle,
    fullMessage
  } = props

  return (
    <StyledModal {...props}>
      <div className={styles.container}>
        <Typography variant="h4">
          {fullTitle || `Split Points`}
        </Typography>
        <Typography variant="subtitle2" style={{margin: '16px auto'}}>
          {fullMessage || `Are you sure you want split the points of this community?`}
        </Typography>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <Button variant="contained" className={cx(styles.btn, styles.cancel)} onClick={onCancel}>Cancel</Button>
          <Button variant="contained" className={cx(styles.btn, styles.split)} onClick={onSplit}>Split</Button>
        </div>
      </div>
    </StyledModal>
  )
}

export default ModalSplit