import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import cx from "classnames";
import {
  Grid,
  Paper,
  Avatar,
  Badge,
  IconButton,
  Typography,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Button,
  Link,
  CircularProgress,
  Input,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { AddCircle, HighlightOff, Visibility, Done } from "@material-ui/icons";
import {
  PageHeader,
  ModalDelete,
  ModalSplit,
  ModalResetEnergyModule,
  ModalExit,
  StyledModal,
  LargeSwitch,
} from "../../components";
import {
  getCommunityByCode,
  deleteCommunityById,
  updateCommunityById,
  upload,
  getCommunityMembersByCode,
  splitPoints,
  resetEnergyModule,
  communityExit as communityExitApi,
  executeUserCheck as executeUserCheckApi,
} from "../../api";
import { pencil } from "../../assets";
import styles from "./CommunityInfo.module.css";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const fields = [
  { key: "address", type: "text", label: "Address" },
  { key: "addressSearch", type: "text", label: "Address sök" },
  { key: "email", type: "text", label: "Community User Admin" },
  { key: "code", type: "number", label: "Activation Code" },
  { key: "apartments", type: "number", label: "Apartments" },
  { key: "residentAmbassadors", type: "text", label: "Community Ambassadors" },
];

const CommunityInfo = () => {
  const history = useHistory();
  const mountedRef = useRef(true);
  const fileRef = useRef();
  const fileRef2 = useRef();
  const { code } = useParams();
  const { apartments } = useParams();
  const [del, setDel] = useState(false);
  const [split, setSplit] = useState(false);
  const [energyModule, setEnergyModule] = useState(false);
  const [communityExit, setCommunityExit] = useState(false);
  const [points2, setPoints2] = useState(false);
  const [points, setPoints] = useState(false);
  const [detail, setDetail] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [isLoadingOnSave, setLoadingOnSave] = useState(false);
  const [isLoadingOnUpload, setLoadingOnUpload] = useState(false);
  const [disposableEngagementPoints, setDisposableEngagementPoints] =
    useState(0);
  const [communityScore, setCommunityScore] = useState(0);
  const [counts, setCounts] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [editField, setEditField] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [isPublicCommunity, setIsPublicCommunity] = useState(null);
  const [isSearchingAdmin, setIsSearchingAdmin] = useState(null);
  const [isExecuteActivityCheck, setIsExecuteActivityCheck] = useState(null);
  const [isPublicLeaderboardCommunity, setIsPublicLeaderboardCommunity] =
    useState(null);
  const [isAwardModule, setIsAwardModule] = useState(null);
  const [isAnalyticsModule, setIsAnalyticsModule] = useState(null);
  const [options, setOptions] = useState([]);
  const [choosenAdmin, setChoosenAdmin] = useState([]);

  // console.log('admin', detail?.admin)
  // console.log('options', options)
  // console.log('filter', options?.filter(val1 => detail?.admin.some(val2 => val1._id.toString() === val2._id.toString())))

  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
        // Default transform is "translate(14px, 20px) scale(1)""
        // This lines up the label with the initial cursor position in the input
        // after changing its padding-left.
        transform: "translate(34px, 20px) scale(1);",
      },
    },
    inputRoot: {
      color: "black",
      background: " #F2F2F2",
      width: "95%",
      borderRadius: 15,
      // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        // Default left padding is 6px
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#b3b3b3",
        borderRadius: 15,
      },
      ".MuiAutocomplete-clearIndicator": {
        color: "black",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#b3b3b3",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#b3b3b3",
      },
    },

    clearIndicator: {
      color: "black",
    },
  }));

  useEffect(() => {
    const fetchCommunityDetail = async () => {
      mountedRef.current && setLoading(true);
      try {
        const community = await getCommunityByCode({ code });
        const { totalMembers, totalGainedRewards, totalActivities } = community;
        const serializer = {};
        const {
          members: { docs },
        } = await getCommunityMembersByCode(code, serializer);
        setOptions(docs);
        if (mountedRef.current) {
          setDetail(community);
          setOptions(docs);
          setIsPublicCommunity(community?.isPublic);
          setIsSearchingAdmin(community?.searchingAdmin);
          setIsPublicLeaderboardCommunity(community?.isPublicLeaderboard);
          setIsExecuteActivityCheck(community?.executeActivityCheck);
          setIsAwardModule(community?.awardModule);
          setIsAnalyticsModule(community?.analyticsModule);
          setDisposableEngagementPoints(community.disposableEngagementPoints);
          setCommunityScore(community.score);
          setSkillList(community.internalSkills);
          setChoosenAdmin(!detail?.admin.lenght ? "No admin" : detail?.admin);
          setCounts([
            {
              id: "id",
              key: "activities",
              label: "Activities",
              value: totalActivities || 0,
            },
            {
              id: "code",
              key: "rewards",
              label: "Rewards",
              value: totalGainedRewards || 0,
            },
            {
              id: "code",
              key: "members",
              label: "Members",
              value: totalMembers || 0,
            },
          ]);
        }
      } catch (error) {
        if (mountedRef.current) {
          setDetail(null);
          setIsPublicCommunity(false);
          setSkillList([]);
          setCounts([]);
        }
        swal(error.message);
      }
      mountedRef.current && setLoading(false);
    };

    fetchCommunityDetail();
    return () => {
      mountedRef.current = false;
    };
  }, [code]);

  useEffect(() => {
    const uploadFile = async () => {
      setLoadingOnUpload(true);
      try {
        const { url } = await upload({ image: selectedFile });
        await updateCommunityById(detail._id, {
          avatar: url,
        });
        mountedRef.current &&
          setDetail({
            ...detail,
            avatar: url,
          });
      } catch (error) {
        console.log(error);
      }
      setLoadingOnUpload(false);
    };

    selectedFile && mountedRef.current && uploadFile();
    return () => {
      setSelectedFile(null);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  useEffect(() => {
    const uploadFile = async () => {
      setLoadingOnUpload(true);
      try {
        const { url } = await upload({ image: selectedFile2 });
        await updateCommunityById(detail._id, {
          communityLogo: url,
        });
        mountedRef.current &&
          setDetail({
            ...detail,
            communityLogo: url,
          });
      } catch (error) {
        console.log(error);
      }
      setLoadingOnUpload(false);
    };

    selectedFile2 && mountedRef.current && uploadFile();
    return () => {
      setSelectedFile2(null);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile, selectedFile2]);

  const handleSetCommunityAdmin = async (value) => {
    console.log("value", value.length);
    setLoadingOnSave(true);
    setChoosenAdmin(value);
    try {
      if (!!value.length) {
        await updateCommunityById(detail._id, {
          key: "changeAdmin",
          users: value.map((e) => e._id),
        });
      }
    } catch (error) {
      console.log(error);
      swal(error.message);
    }
    setLoadingOnSave(false);
  };

  const handleCommunityPublicity = async () => {
    setLoadingOnSave(true);
    try {
      const { isPublic } = await updateCommunityById(detail._id, {
        isPublic: !isPublicCommunity,
      });
      setIsPublicCommunity(isPublic);
    } catch (error) {
      console.log(error);
    }
    setLoadingOnSave(false);
  };

  const handleResidentAdminSearch = async () => {
    setLoadingOnSave(true);
    try {
      const { searchingAdmin } = await updateCommunityById(detail._id, {
        searchingAdmin: !isSearchingAdmin,
      });
      setIsSearchingAdmin(searchingAdmin);
    } catch (error) {
      console.log(error);
    }
    setLoadingOnSave(false);
  };

  const handleCommunityLeaderboard = async () => {
    setLoadingOnSave(true);
    try {
      const { isPublicLeaderboard } = await updateCommunityById(detail._id, {
        isPublicLeaderboard: !isPublicLeaderboardCommunity,
      });
      // console.log(isPublicLeaderboard);
      setIsPublicLeaderboardCommunity(isPublicLeaderboard);
    } catch (error) {
      console.log(error);
    }
    setLoadingOnSave(false);
  };

  const handleExecuteActivityCheck = async () => {
    setLoadingOnSave(true);
    try {
      const { isExecuteActivityChecker } = await executeUserCheckApi({
        communityId: detail._id,
        userAction: isExecuteActivityCheck === true ? true : false,
        communityAction: isExecuteActivityCheck === true ? false : true,
      });
      setIsExecuteActivityCheck(!isExecuteActivityCheck);
    } catch (error) {
      console.log(error);
    }
    setLoadingOnSave(false);
  };

  const handleAwardModule = async () => {
    setLoadingOnSave(true);
    try {
      const { awardModule } = await updateCommunityById(detail._id, {
        awardModule: !isAwardModule,
      });
      // console.log(isPublicLeaderboard);
      setIsAwardModule(awardModule);
    } catch (error) {
      console.log(error);
    }
    setLoadingOnSave(false);
  };

  const handleAnalyticsModule = async () => {
    setLoadingOnSave(true);
    try {
      const { analyticsModule } = await updateCommunityById(detail._id, {
        analyticsModule: !isAnalyticsModule,
      });
      // console.log(isPublicLeaderboard);
      setIsAnalyticsModule(analyticsModule);
    } catch (error) {
      console.log(error);
    }
    setLoadingOnSave(false);
  };

  const handleUpdateData = async (key) => {
    if (editField) {
      const { key: editKey, tempValue } = editField;
      const currentValue = detail[editKey];

      if (currentValue !== tempValue) {
        setLoadingOnSave(true);
        try {
          const updatedData = await updateCommunityById(detail._id, {
            [editKey]: currentValue,
          });

          setDetail({
            ...detail,
            [editKey]: updatedData[editKey],
          });
        } catch (error) {
          setDetail({
            ...detail,
            [editKey]: tempValue,
          });
        }
        setLoadingOnSave(false);
      }
      setEditField(null);
    } else {
      setEditField({
        key,
        tempValue: key === "email" ? detail?.admin?.email : detail[key],
      });
    }
  };
  const handleDelete = async () => {
    try {
      await deleteCommunityById(detail._id);
      closeModalDelete();
      history.goBack();
    } catch (error) {}
  };

  const handleSplit = async () => {
    try {
      await splitPoints(detail._id);
      closeModalSplit();
    } catch (error) {}
  };

  const handleResetEnergyModule = async () => {
    try {
      await resetEnergyModule(detail._id);
      closeModalResetEnergyModule();
    } catch (error) {}
  };

  const handleExit = async () => {
    try {
      await communityExitApi(detail._id);
      closeModalExit();
    } catch (error) {}
  };

  const handleAddDisposablePoints = async () => {
    try {
      const result = await updateCommunityById(detail._id, {
        disposableEngagementPoints,
      });

      await setDetail({
        ...detail,
        disposableEngagementPoints: result?.disposableEngagementPoints,
      });
    } catch (err) {
      // do something
    }

    closeModalAddPoints();
  };

  const handleAddCommunityPoints = async () => {
    try {
      const result = await updateCommunityById(detail._id, {
        score: communityScore,
      });

      await setDetail({
        ...detail,
        score: result?.score,
      });
    } catch (err) {
      // do something
    }

    closeModalAddPoints2();
  };

  const loadMoreSkills = () => {
    history.push(`/communities/${code}/skills`);
  };

  const openModalDelete = () => {
    setDel(true);
  };

  const closeModalDelete = () => {
    setDel(false);
  };

  const openModalSplit = () => {
    setSplit(true);
  };

  const closeModalSplit = () => {
    setSplit(false);
  };

  const openModalResetEnergyModule = () => {
    setEnergyModule(true);
  };

  const closeModalResetEnergyModule = () => {
    setEnergyModule(false);
  };

  const openModalExit = () => {
    setCommunityExit(true);
  };

  const closeModalExit = () => {
    setCommunityExit(false);
  };

  const openModalAddPoints = () => {
    if (
      parseInt(disposableEngagementPoints) !==
      parseInt(detail.disposableEngagementPoints)
    ) {
      setDisposableEngagementPoints(detail.disposableEngagementPoints);
    }

    setPoints(true);
  };

  const openModalAddCommunityScore = () => {
    if (parseInt(communityScore) !== parseInt(detail.score)) {
      setCommunityScore(detail.score);
    }

    setPoints2(true);
  };

  const closeModalAddPoints = () => {
    setPoints(false);
  };

  const closeModalAddPoints2 = () => {
    setPoints2(false);
  };

  const handleEdit = async (key, value) => {
    const updateValue = key === "code" ? value.slice(0, 5) : value;

    if (key === "email") {
      await setDetail({
        ...detail,
        admin: {
          ...detail.admin,
          email: updateValue,
        },
      });
    } else {
      await setDetail({
        ...detail,
        [key]: updateValue,
      });
    }
  };

  const classes = useStyles();
  return (
    <>
      <Grid container className={styles.container} spacing={3}>
        <PageHeader
          title="Community Information"
          onResetEnergyModule={openModalResetEnergyModule}
          onSplit={openModalSplit}
          onExit={openModalExit}
          onDelete={openModalDelete}
          onBack={history.goBack}
        ></PageHeader>
        {isLoading || !detail ? (
          <>
            <Grid item xs={6}>
              <Skeleton
                variant="rect"
                animation="wave"
                width={528}
                height={687}
              />
            </Grid>
            <Grid item xs={6}>
              <Skeleton
                variant="rect"
                animation="wave"
                width={528}
                height={687}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={6}>
              <Paper className={styles.paper}>
                <Badge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  badgeContent={
                    !isLoadingOnUpload && (
                      <IconButton
                        className={styles.edit}
                        size="small"
                        onClick={() => fileRef.current.click()}
                      >
                        <Avatar
                          src={pencil}
                          alt="pencil"
                          style={{ height: "30px", width: "30px" }}
                        />
                      </IconButton>
                    )
                  }
                >
                  {isLoadingOnUpload ? (
                    <CircularProgress
                      className={styles.communityAvatar}
                      style={{ border: 0 }}
                    />
                  ) : (
                    <Avatar
                      alt="Community Avatar"
                      src={`http://i0.wp.com/${detail?.avatar?.slice(8)}?w=300`}
                      className={styles.communityAvatar}
                    />
                  )}
                </Badge>
                <input
                  type="file"
                  id="file"
                  ref={fileRef}
                  style={{ display: "none" }}
                  onChange={(e) => setSelectedFile(e.target.files[0])}
                />

                <Grid item xs={12} style={{ marginBottom: "10px" }}>
                  {editField?.key === "name" ? (
                    <FormControl
                      variant="standard"
                      fullWidth
                      style={{ marginTop: "7px" }}
                    >
                      <Input
                        value={detail.name}
                        placeholder="Community Name"
                        inputProps={{ style: { textAlign: "center" } }}
                        className={styles.communityName}
                        onChange={(e) => handleEdit("name", e.target.value)}
                        endAdornment={
                          <InputAdornment position="end">
                            {isLoadingOnSave && editField?.key === "name" ? (
                              <CircularProgress
                                size={20}
                                color="secondary"
                              ></CircularProgress>
                            ) : (
                              <IconButton
                                className={
                                  editField?.key !== "name"
                                    ? styles.edit
                                    : styles.done
                                }
                                size="small"
                                disabled={
                                  editField?.key !== "name" &&
                                  !!editField &&
                                  isLoadingOnSave
                                }
                                onClick={() => handleUpdateData("name")}
                              >
                                {"name" === editField?.key ? (
                                  <Done
                                    fontSize="inherit"
                                    style={{ color: "#ffffff" }}
                                  ></Done>
                                ) : (
                                  <Avatar
                                    src={pencil}
                                    alt="pencil"
                                    style={{ height: "20px", width: "20px" }}
                                  />
                                )}
                              </IconButton>
                            )}
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  ) : (
                    <Button
                      style={{ marginTop: "10px" }}
                      onClick={() => handleUpdateData("name")}
                    >
                      <Typography className={styles.communityName}>
                        {detail.name}
                      </Typography>
                    </Button>
                  )}
                </Grid>
                <Grid
                  container
                  spacing={5}
                  style={{ marginTop: "47px", marginBottom: "21px" }}
                >
                  <Grid item xs={6}>
                    <Typography className={styles.label}>
                      Community Score
                    </Typography>
                    <FormControl variant="outlined">
                      <OutlinedInput
                        placeholder="Community Score"
                        value={detail.score}
                        className={styles.input}
                        disabled={true}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              onClick={openModalAddCommunityScore}
                            >
                              <AddCircle
                                edge="end"
                                style={{ color: "#389DA4" }}
                              ></AddCircle>
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography className={styles.label}>
                      Disposable Engagement Points
                    </Typography>
                    <FormControl variant="outlined">
                      <OutlinedInput
                        placeholder="Disposable Engagement Points"
                        className={styles.input}
                        disabled={true}
                        value={detail.disposableEngagementPoints}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              onClick={openModalAddPoints}
                            >
                              <AddCircle
                                edge="end"
                                style={{ color: "#389DA4" }}
                              ></AddCircle>
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                {fields.map((field) =>
                  field?.key === "email" ? (
                    <Grid
                      item
                      xs={12}
                      key={field.key}
                      style={{ marginBottom: "10px" }}
                    >
                      <Typography
                        className={cx(styles.label, styles.alignLeft)}
                      >
                        {field.label}
                      </Typography>
                      <Autocomplete
                        classes={classes}
                        // style={{ width: 50}}
                        multiple
                        id="checkboxes-tags-demo"
                        filterSelectedOptions={true}
                        options={options}
                        defaultValue={options?.filter((val1) =>
                          detail?.admin === null
                            ? "No Admins"
                            : detail?.admin.some(
                                (val2) =>
                                  val1._id.toString() === val2._id.toString()
                              )
                        )}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.email}
                        onChange={(event, value) => {
                          handleSetCommunityAdmin(value);
                        }}
                        renderOption={(option, { selected }) => (
                          <React.Fragment>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.email}
                          </React.Fragment>
                        )}
                        style={{ width: 500 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="admin email"
                          />
                        )}
                      />
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      key={field.key}
                      style={{ marginBottom: "10px" }}
                    >
                      <Typography
                        className={cx(styles.label, styles.alignLeft)}
                      >
                        {field.label}
                      </Typography>
                      <FormControl variant="outlined" fullWidth>
                        <OutlinedInput
                          value={detail[field.key]}
                          type={field.type}
                          placeholder={field.label}
                          className={styles.input}
                          disabled={field.key !== editField?.key}
                          onChange={(e) =>
                            handleEdit(field.key, e.target.value)
                          }
                          endAdornment={
                            <InputAdornment position="end">
                              {isLoadingOnSave &&
                              field.key === editField?.key ? (
                                <CircularProgress
                                  size={20}
                                  color="secondary"
                                ></CircularProgress>
                              ) : (
                                <IconButton
                                  className={
                                    field.key !== editField?.key
                                      ? styles.edit
                                      : styles.done
                                  }
                                  size="small"
                                  disabled={
                                    field.key !== editField?.key &&
                                    !!editField &&
                                    isLoadingOnSave
                                  }
                                  onClick={() => handleUpdateData(field.key)}
                                >
                                  {field.key === editField?.key ? (
                                    <Done
                                      fontSize="inherit"
                                      style={{ color: "#ffffff" }}
                                    ></Done>
                                  ) : (
                                    <Avatar
                                      src={pencil}
                                      alt="pencil"
                                      style={{ height: "20px", width: "20px" }}
                                    />
                                  )}
                                </IconButton>
                              )}
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </Grid>
                  )
                )}
                <Grid item xs={20}>
                  <Typography className={cx(styles.label, styles.alignLeft)}>
                    Community Privacy Setting
                  </Typography>
                </Grid>
                <Grid container spacing={5}>
                  <Grid
                    item
                    xs={9}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography>
                      {isPublicCommunity
                        ? "Community is open for everyone."
                        : "Community needs key to be accessed."}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <LargeSwitch
                      disabled={isLoadingOnSave}
                      checked={isPublicCommunity}
                      onChange={handleCommunityPublicity}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={5}>
                  <Grid
                    item
                    xs={9}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography>
                      {isSearchingAdmin
                        ? "Looking for new admin"
                        : "Admin slots are full"}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <LargeSwitch
                      disabled={isLoadingOnSave}
                      checked={isSearchingAdmin}
                      onChange={handleResidentAdminSearch}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={5}>
                  <Grid
                    item
                    xs={9}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography>
                      {isAwardModule
                        ? "Award Module is active"
                        : "Award Module is not active"}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <LargeSwitch
                      disabled={isLoadingOnSave}
                      checked={isAwardModule}
                      onChange={handleAwardModule}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={5}>
                  <Grid
                    item
                    xs={9}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography>
                      {isAnalyticsModule
                        ? "Analytics Module is active"
                        : "Analytics Module is not active"}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <LargeSwitch
                      disabled={isLoadingOnSave}
                      checked={isAnalyticsModule}
                      onChange={handleAnalyticsModule}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={5}>
                  <Grid
                    item
                    xs={9}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography>
                      {isPublicLeaderboardCommunity
                        ? "Community is exposed for leaderboard"
                        : "Community is hidden for leaderboard"}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <LargeSwitch
                      disabled={isLoadingOnSave}
                      checked={isPublicLeaderboardCommunity}
                      onChange={handleCommunityLeaderboard}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={5}>
                  <Grid
                    item
                    xs={9}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography>
                      {isExecuteActivityCheck
                        ? "User check is active"
                        : "User check is inactive"}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <LargeSwitch
                      disabled={isLoadingOnSave}
                      checked={isExecuteActivityCheck}
                      onChange={handleExecuteActivityCheck}
                    />
                  </Grid>
                </Grid>

                <Badge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  badgeContent={
                    !isLoadingOnUpload && (
                      <IconButton
                        className={styles.edit}
                        size="small"
                        onClick={() => fileRef2.current.click()}
                      >
                        <Avatar
                          src={pencil}
                          alt="pencil"
                          style={{ height: "30px", width: "30px" }}
                        />
                      </IconButton>
                    )
                  }
                >
                  {isLoadingOnUpload ? (
                    <CircularProgress
                      className={styles.communityAvatar}
                      style={{ border: 0 }}
                    />
                  ) : (
                    <Avatar
                      alt="Community Logo"
                      src={`http://i0.wp.com/${detail?.communityLogo?.slice(
                        8
                      )}?w=300`}
                      className={styles.communityLogo}
                    />
                  )}
                </Badge>
                <input
                  type="file"
                  id="file"
                  ref={fileRef2}
                  style={{ display: "none" }}
                  onChange={(e) => setSelectedFile2(e.target.files[0])}
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={styles.paper}>
                <Grid container spacing={4} style={{ marginTop: "27px" }}>
                  {counts.map((data) => (
                    <Grid item xs={12 / counts.length} key={data.key}>
                      <Link
                        href={`/communities/${detail[data.id]}/${data.key}`}
                        style={{ textDecoration: "none" }}
                      >
                        <div>
                          <Typography variant="h3" className={styles.count}>
                            {data.value}
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography variant="subtitle1">
                              {data.label}
                            </Typography>
                            <IconButton
                              size="small"
                              className={styles.eye}
                              disabled
                            >
                              <Visibility fontSize="small"></Visibility>
                            </IconButton>
                          </div>
                        </div>
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            </Grid>
          </>
        )}
      </Grid>

      <ModalDelete
        content="Community"
        open={del}
        onCancel={closeModalDelete}
        onDelete={handleDelete}
      ></ModalDelete>

      <ModalSplit
        content="Community"
        open={split}
        onCancel={closeModalSplit}
        onSplit={handleSplit}
      ></ModalSplit>

      <ModalResetEnergyModule
        content="Community"
        open={energyModule}
        onCancel={closeModalResetEnergyModule}
        onResetEnergyModule={handleResetEnergyModule}
      ></ModalResetEnergyModule>

      <ModalExit
        content="Community"
        open={communityExit}
        onCancel={closeModalExit}
        onExit={handleExit}
      ></ModalExit>

      <StyledModal open={points} onCancel={closeModalAddPoints}>
        <div className={styles.modal}>
          <IconButton
            size="small"
            onClick={closeModalAddPoints}
            className={styles.close}
          >
            <HighlightOff
              fontSize="large"
              style={{ color: "#320064" }}
            ></HighlightOff>
          </IconButton>
          <Typography variant="h4">Add Disposable Engagement Points</Typography>
          <Typography variant="subtitle2" style={{ margin: "16px auto" }}>
            Add a value to top up this community’s DEP
          </Typography>
          <div>
            <FormControl variant="outlined">
              <OutlinedInput
                type="number"
                placeholder="Disposable Engagement Points"
                className={styles.input}
                value={disposableEngagementPoints}
                onChange={(e) => setDisposableEngagementPoints(e.target.value)}
              />
            </FormControl>
          </div>
          <Button
            // disabled={disposableEngagementPoints < detail?.disposableEngagementPoints}
            variant="contained"
            classes={{
              root: cx(styles.btn, styles.export),
              label: styles.btnLabel,
            }}
            onClick={handleAddDisposablePoints}
          >
            Add
          </Button>
        </div>
      </StyledModal>

      <StyledModal open={points2} onCancel={closeModalAddPoints2}>
        <div className={styles.modal}>
          <IconButton
            size="small"
            onClick={closeModalAddPoints2}
            className={styles.close}
          >
            <HighlightOff
              fontSize="large"
              style={{ color: "#320064" }}
            ></HighlightOff>
          </IconButton>
          <Typography variant="h4">Add Community Score</Typography>
          <Typography variant="subtitle2" style={{ margin: "16px auto" }}>
            Add a value to top up this community
          </Typography>
          <div>
            <FormControl variant="outlined">
              <OutlinedInput
                type="number"
                placeholder="Community Score"
                className={styles.input}
                value={communityScore}
                onChange={(e) => setCommunityScore(e.target.value)}
              />
            </FormControl>
          </div>
          <Button
            // disabled={disposableEngagementPoints < detail?.disposableEngagementPoints}
            variant="contained"
            classes={{
              root: cx(styles.btn, styles.export),
              label: styles.btnLabel,
            }}
            onClick={handleAddCommunityPoints}
          >
            Add
          </Button>
        </div>
      </StyledModal>
    </>
  );
};

export default CommunityInfo;
