import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import cx from "classnames";
import {
  Grid,
  Paper,
  Avatar,
  Badge,
  Typography,
  FormControl,
  OutlinedInput,
  Button,
  InputAdornment,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import { Done } from "@material-ui/icons";
import { pencil } from "../../assets";
import { Skeleton } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { PageHeader, ModalDelete, LargeSwitch } from "../../components";
import {
  getUserById,
  updateUserById,
  deleteUserById,
  updateAmbassador,
  updateAssociatedCommunity,
  updateCommunityById,
  updateResidentAmbassador,
  getAllCommunities,
  updateCommunityByAdmin,
} from "../../api";
import { userTypes } from "../../contants";
import swal from "sweetalert";
import styles from "./UserInfo.module.css";

const fields = [
  { key: "address", label: "Address" },
  { key: "email", label: "Email" },
  { key: "phone", label: "Phone" },
  { key: "community", label: "Community" },
  { key: "refCode", label: "Personal Code" },
  { key: "isRedeemed", label: "Redeemed Code" },
  { key: "energyModuleFinished", label: "Energy Module done" },
  { key: "awards", label: "Awards" },
  { key: "skills", label: "Skills" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      // Default transform is "translate(14px, 20px) scale(1)""
      // This lines up the label with the initial cursor position in the input
      // after changing its padding-left.
      transform: "translate(34px, 20px) scale(1);",
    },
  },
  inputRoot: {
    color: "black",
    background: " #F2F2F2",
    borderRadius: 15,
    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#b3b3b3",
      borderRadius: 15,
    },
    ".MuiAutocomplete-clearIndicator": {
      color: "black",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#b3b3b3",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#b3b3b3",
    },
  },

  clearIndicator: {
    color: "black",
  },
}));

const UserInfo = () => {
  const history = useHistory();
  const { id } = useParams();
  const [del, setDel] = useState(false);
  const [paymentAdded, setPaymentAdded] = useState(true);
  const [detail, setDetail] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [counts, setCounts] = useState([]);
  const [editField, setEditField] = useState(null);
  const [isLoadingOnSave, setLoadingOnSave] = useState(false);
  const [isAmbassador, setIsAmbassador] = useState(false);
  const [isResidentAmbassador, setIsResidentAmbassador] = useState(false);
  const [isAssociatedCommunities, setIsAssociatedCommunities] = useState(false);
  const [isCommunityAdmin, setIsCommunityAdmin] = useState(false);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [isFetchLoading, setIsFetchLoading] = useState(false);
  const [choosenCommunity, setChoosenCommunity] = useState("");
  console.log(options);
  useEffect(() => {
    const fetchUserDetail = async () => {
      setLoading(true);
      try {
        const { user } = await getUserById({ id });
        setDetail(user);
        setCounts([
          {
            key: "totalDistributedActivity",
            label: "Created Activities",
            value: user.totalDistributedActivity,
          },
          {
            key: "performedActivities",
            label: "Completed Activities",
            value: user.totalCompletedActivity,
          },
          {
            key: "claimedQrCodes",
            label: "Qr Codes scanned",
            value: user.claimedQrCodes,
          },
        ]);
        setPaymentAdded(user.isPaymentAdded);
        setIsCommunityAdmin(user.isCommunityAdmin);
        setIsAmbassador(user?.isAmbassador === true ? true : false);
        setIsResidentAmbassador(
          user?.isResidentAmbassador === true ? true : false
        );
        setIsAssociatedCommunities(
          user?.associatedCommunity === true ? true : false
        );
        setChoosenCommunity(user?.communityId?.name);
      } catch (error) {
        setDetail({});
      }
      setLoading(false);
    };
    fetchUserDetail();
    return () => {
      setDetail({});
    };
  }, [id]);

  useEffect(() => {
    fetchCommunityList();
    return () => {
      // eslint-disable-next-line no-unused-expressions
      undefined;
    };
  }, [choosenCommunity]);

  const handleDelete = async () => {
    try {
      await deleteUserById(id);
      await closeModalDelete();
      history.replace("/users");
    } catch (error) {
      console.log(error);
      swal(error.message);
    }
  };
  const fetchCommunityList = async (params) => {
    try {
      setIsFetchLoading(true);
      const serializer = {
        name: params,
      };
      const {
        communities: { docs, ...paginate },
      } = await getAllCommunities(params, serializer);
      setOptions(docs);
    } catch (e) {
      setOptions([]);
      swal(e.message);
    }
    setIsFetchLoading(false);
  };
  const handleSetAmbassador = async () => {
    setLoadingOnSave(true);
    try {
      const data = await updateAmbassador(detail._id, {
        isAmbassador: !isAmbassador,
      });
      setIsAmbassador(data?.data?.isAmbassador);
    } catch (error) {
      swal(error.message);
      console.log(error);
    }
    setLoadingOnSave(false);
  };

  const handleSetResidentAmbassador = async () => {
    setLoadingOnSave(true);
    try {
      const data = await updateResidentAmbassador(detail._id, {
        isResidentAmbassador: !isResidentAmbassador,
        communityId: detail.communityId.id,
      });
      setIsResidentAmbassador(data?.data?.isResidentAmbassador);
    } catch (error) {
      swal(error.message);
      console.log(error);
    }
    setLoadingOnSave(false);
  };

  const handleSetAssociatedCommunities = async () => {
    setLoadingOnSave(true);
    try {
      const data = await updateAssociatedCommunity(detail._id, {
        associatedCommunity: !isAssociatedCommunities,
      });
      console.log(isAssociatedCommunities);
      setIsAssociatedCommunities(data?.data?.associatedCommunity);
    } catch (error) {
      swal(error.message);
      console.log(error);
    }
    setLoadingOnSave(false);
  };

  const handleChangeCommunity = async (community) => {
    setLoadingOnSave(true);
    try {
      if (community) {
        await updateCommunityByAdmin(detail._id, {
          communityId: community?.id,
        });
        setChoosenCommunity(community?.name);
        swal(`Community changed to \n\n${community?.name}`);
      }
    } catch (error) {
      swal(error.message);
      console.log(error);
    }
    setLoadingOnSave(false);
  };
  const handleSetCommunityAdmin = async () => {
    setLoadingOnSave(true);
    try {
      await updateCommunityById(detail?.communityId?._id, {
        isCommunityAdmin: !isCommunityAdmin,
        userId: detail?._id,
      });
      setIsCommunityAdmin(!isCommunityAdmin);
    } catch (error) {
      console.log(error);
      swal(error.message);
    }
    setLoadingOnSave(false);
  };

  const openModalDelete = () => {
    setDel(true);
  };

  const closeModalDelete = () => {
    setDel(false);
  };

  const handleChangeAdmin = async () => {
    try {
      const userType =
        detail.userType === userTypes.administrator
          ? userTypes.normalUser
          : userTypes.administrator;
      const updated = await updateUserById(id, { userType });
      if (updated?.response?.data?.error) {
        swal(updated?.response?.data?.message);
      } else {
        setDetail({
          ...detail,
          userType,
        });
      }
    } catch (error) {
      swal(error.message);
      console.log("error");
    }
  };

  const handleUpdateData = async (key) => {
    if (editField) {
      const { key: editKey, tempValue } = editField;
      const currentValue = detail[editKey];

      if (currentValue !== tempValue) {
        setLoadingOnSave(true);
        try {
          const updated = await updateUserById(id, {
            [editKey]: currentValue,
          });

          if (updated?.response?.data?.error) {
            swal(updated?.response?.data?.message);
          } else {
            setDetail({
              ...detail,
              [editKey]: updated[editKey],
            });
          }
        } catch (error) {
          swal(error.message);
          setDetail({
            ...detail,
            [editKey]: tempValue,
          });
        }
        setLoadingOnSave(false);
      }
      setEditField(null);
    } else {
      setEditField({
        key,
        tempValue: detail[key],
      });
    }
  };

  const formattedValue = (source, key) => {
    switch (key) {
      case "community":
        return source.communityId ? source.communityId.name : "";
      default:
        return source[key];
    }
  };
  const classes = useStyles();
  return (
    <>
      <Grid container className={styles.container} spacing={3}>
        <PageHeader
          title="User"
          onDelete={openModalDelete}
          onBack={history.goBack}
        ></PageHeader>
        {isLoading || !detail ? (
          <>
            <Grid item xs={6}>
              <Skeleton
                variant="rect"
                animation="wave"
                width={528}
                height={687}
              />
            </Grid>
            <Grid item xs={6}>
              <Skeleton
                variant="rect"
                animation="wave"
                width={528}
                height={313}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={6}>
              <Paper className={styles.paper}>
                <Badge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  <Avatar
                    alt="Community Avatar"
                    src={`http://i0.wp.com/${detail?.avatar?.slice(8)}?w=300`}
                    className={styles.communityAvatar}
                  />
                </Badge>
                <Typography className={styles.communityName}>
                  {detail.fullName}
                </Typography>
                <Grid
                  container
                  spacing={5}
                  style={{ marginTop: "47px", marginBottom: "21px" }}
                >
                  <Grid item xs={6}>
                    <Typography className={styles.label}>User ID</Typography>
                    <FormControl variant="outlined">
                      <OutlinedInput
                        placeholder="User ID"
                        className={styles.fields}
                        disabled={true}
                        value={detail.id}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className={styles.label}>
                      Engagement Score
                    </Typography>
                    <FormControl variant="standard" fullWidth>
                      <OutlinedInput
                        value={detail.score}
                        placeholder="Engagement Score"
                        inputProps={{ style: { textAlign: "center" } }}
                        className={styles.fields}
                        disabled={editField?.key !== "score"}
                        type="number"
                        onChange={(e) =>
                          setDetail({
                            ...detail,
                            score: e.target.value,
                          })
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            {isLoadingOnSave ? (
                              <CircularProgress
                                size={20}
                                color="secondary"
                              ></CircularProgress>
                            ) : (
                              <IconButton
                                className={
                                  editField?.key !== "score"
                                    ? styles.edit
                                    : styles.done
                                }
                                size="small"
                                disabled={
                                  editField?.key !== "score" &&
                                  !!editField &&
                                  isLoadingOnSave
                                }
                                onClick={() => handleUpdateData("score")}
                              >
                                {editField?.key === "score" ? (
                                  <Done
                                    fontSize="inherit"
                                    style={{ color: "#ffffff" }}
                                  ></Done>
                                ) : (
                                  <Avatar
                                    src={pencil}
                                    alt="pencil"
                                    style={{ height: "20px", width: "20px" }}
                                  />
                                )}
                              </IconButton>
                            )}
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className={styles.label}>
                      Total Score
                    </Typography>
                    <FormControl variant="standard" fullWidth>
                      <OutlinedInput
                        value={detail.fullScore}
                        placeholder="Total Score"
                        inputProps={{ style: { textAlign: "center" } }}
                        className={styles.fields}
                        disabled={editField?.key !== "fullScore"}
                        type="number"
                        onChange={(e) =>
                          setDetail({
                            ...detail,
                            fullScore: e.target.value,
                          })
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            {isLoadingOnSave ? (
                              <CircularProgress
                                size={20}
                                color="secondary"
                              ></CircularProgress>
                            ) : (
                              <IconButton
                                className={
                                  editField?.key !== "fullScore"
                                    ? styles.edit
                                    : styles.done
                                }
                                size="small"
                                disabled={
                                  editField?.key !== "fullScore" &&
                                  !!editField &&
                                  isLoadingOnSave
                                }
                                onClick={() => handleUpdateData("fullScore")}
                              >
                                {editField?.key === "fullScore" ? (
                                  <Done
                                    fontSize="inherit"
                                    style={{ color: "#ffffff" }}
                                  ></Done>
                                ) : (
                                  <Avatar
                                    src={pencil}
                                    alt="pencil"
                                    style={{ height: "20px", width: "20px" }}
                                  />
                                )}
                              </IconButton>
                            )}
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                {fields.map((field) =>
                  field.key === "community" ? (
                    <Grid
                      item
                      xs={12}
                      key={field.key}
                      style={{ marginBottom: "10px" }}
                    >
                      <Typography
                        className={cx(styles.label, styles.alignLeft)}
                      >
                        {field.label}
                      </Typography>
                      <Autocomplete
                        classes={classes}
                        options={options}
                        disableClearable
                        onFocus={() => setChoosenCommunity("")}
                        inputValue={choosenCommunity}
                        filterSelectedOptions={false}
                        getOptionSelected={(option, value) =>
                          option.name === value.name
                        }
                        getOptionLabel={(option) => option.name}
                        onChange={(event, newValue) => {
                          console.log(event);
                          handleChangeCommunity(newValue);
                        }}
                        id="asynchronous-demo"
                        open={open}
                        onOpen={() => {
                          setOpen(true);
                        }}
                        onClose={() => {
                          setOpen(false);
                        }}
                        loading={isFetchLoading}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              variant="outlined"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {isFetchLoading ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          );
                        }}
                      />
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      key={field.key}
                      style={{ marginBottom: "10px" }}
                    >
                      <Typography
                        className={cx(styles.label, styles.alignLeft)}
                      >
                        {field.label}
                      </Typography>
                      <FormControl variant="outlined" fullWidth>
                        <OutlinedInput
                          placeholder={field.label}
                          className={styles.fields}
                          disabled={true}
                          value={formattedValue(detail, field.key)}
                        />
                      </FormControl>
                    </Grid>
                  )
                )}
              </Paper>
              <Grid container spacing={5} style={{ marginTop: "1%" }}>
                <Grid
                  item
                  xs={3}
                  style={{
                    displsay: "flex",
                    alignItems: "center",
                    marginLeft: "3%",
                  }}
                >
                  <Typography style={{ fontSize: 24 }}>
                    Multiple Associated Communities
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <LargeSwitch
                    disabled={isLoadingOnSave}
                    checked={isAssociatedCommunities}
                    onChange={handleSetAssociatedCommunities}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={5} style={{ marginTop: "1%" }}>
                <Grid
                  item
                  xs={3}
                  style={{
                    displsay: "flex",
                    alignItems: "center",
                    marginLeft: "3%",
                  }}
                >
                  <Typography style={{ fontSize: 24 }}>
                    Administrator
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <LargeSwitch
                    disabled={isLoadingOnSave}
                    checked={isAmbassador}
                    onChange={handleSetAmbassador}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={5} style={{ marginTop: "1%" }}>
                <Grid
                  item
                  xs={3}
                  style={{
                    displsay: "flex",
                    alignItems: "center",
                    marginLeft: "3%",
                  }}
                >
                  <Typography style={{ fontSize: 24 }}>
                    Resident Ambassador
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <LargeSwitch
                    disabled={isLoadingOnSave}
                    checked={isResidentAmbassador}
                    onChange={handleSetResidentAmbassador}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Paper className={styles.paper}>
                <Grid container spacing={4} style={{ marginTop: "27px" }}>
                  {counts.map((data) => (
                    <Grid item xs={12 / counts.length} key={data.key}>
                      <Typography variant="h3" className={styles.count}>
                        {data.value}
                      </Typography>
                      <Typography variant="subtitle1">{data.label}</Typography>
                    </Grid>
                  ))}
                  <Grid item xs={6}>
                    <Typography>Dashboard Admin</Typography>
                    <LargeSwitch
                      checked={detail.userType === userTypes.administrator}
                      onChange={handleChangeAdmin}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{ marginBottom: "13px" }}>
                      Payment Added
                    </Typography>
                    <Button
                      variant="contained"
                      disabled={true}
                      size="large"
                      className={cx(
                        styles.btn,
                        paymentAdded ? null : styles.unselected
                      )}
                      // onClick={() => setPaymentAdded(true)}
                    >
                      <Typography variant="body1" className={styles.options}>
                        Yes
                      </Typography>
                    </Button>
                    <Button
                      variant="contained"
                      disabled={true}
                      size="large"
                      className={cx(
                        styles.btn,
                        !paymentAdded ? null : styles.unselected
                      )}
                      // onClick={() => setPaymentAdded(false)}
                    >
                      <Typography variant="body1" className={styles.options}>
                        No
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </>
        )}
      </Grid>

      <ModalDelete
        content="User"
        open={del}
        onCancel={closeModalDelete}
        onDelete={handleDelete}
      ></ModalDelete>
    </>
  );
};

export default UserInfo;
