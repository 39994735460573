import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  IconButton,
  Typography,
  FormControl,
  OutlinedInput,
  Button,
  Badge,
  Avatar,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import { HighlightOff, Done } from "@material-ui/icons";
import {
  getAllRewards,
  createNewReward,
  updateRewardById,
  deleteRewardById,
  upload,
  getAllCommunities,
} from "../../api";
import { pencil } from "../../assets";
import cx from "classnames";
import styles from "./Rewards.module.css";
import {
  PageHeader,
  DataTable,
  StyledModal,
  ModalDelete,
} from "../../components";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const headers = [
  { label: "Image", key: "icon", type: "avatar" },
  { label: "Name", key: "title" },
  { label: "Description", key: "description" },
  { label: "Description English", key: "descriptionEnglish" },
  { label: "Category", key: "category" },
  { label: "Engagement Points", key: "score" },
];

const fields = [
  { key: "title", type: "text", label: "Reward Name" },
  { key: "category", type: "text", label: "Category" },
  { key: "description", type: "text", label: "Description" },
  { key: "descriptionEnglish", type: "text", label: "Description English" },
  { key: "score", type: "number", label: "Engagement Points" },
];

const fields2 = [{ key: "communityId", label: "Community" }];

const limitData = 100;

const Rewards = () => {
  const mountedRef = useRef(true);
  const file = useRef();
  const [rewardList, setRewardList] = useState(null);
  const [rewardListExport, setRewardListExport] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [serializer, setSerializer] = useState({});
  const [pagination, setPagination] = useState({});
  const [detail, setDetail] = useState(null);
  const [isLoadingOnUpload, setLoadingOnUpload] = useState(false);
  const [isLoadingOnSave, setLoadingOnSave] = useState(false);
  const [choosenCommunities, setChoosenCommunities] = useState([]);
  const [editField, setEditField] = useState(null);

  const [options, setOptions] = useState([
    {
      _id: 1,
      category: "global",
    },
    {
      _id: 2,
      category: "donation",
    },
    {
      _id: 3,
      category: "local",
    },
  ]);

  const [options2, setOptions2] = useState([]);

  const [modalState, setModalState] = useState({
    isShow: false,
    isEdit: false,
  });
  const [isLoading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [del, setDel] = useState(false);
  const [selectedReward, setSelectedReward] = useState({
    icon: "",
    title: "",
    score: 0,
    description: "",
    isActive: true,
    communities: [],
  });

  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
        // Default transform is "translate(14px, 20px) scale(1)""
        // This lines up the label with the initial cursor position in the input
        // after changing its padding-left.
        transform: "translate(34px, 20px) scale(1);",
      },
    },
    inputRoot: {
      color: "black",
      background: " #F2F2F2",
      width: "95%",
      borderRadius: 15,
      // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        // Default left padding is 6px
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#b3b3b3",
        borderRadius: 15,
      },
      ".MuiAutocomplete-clearIndicator": {
        color: "black",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#b3b3b3",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#b3b3b3",
      },
    },

    clearIndicator: {
      color: "black",
    },
  }));

  useEffect(() => {
    mountedRef.current && fetchCommunityList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serializer, selectedReward]);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current && setRewardList(null);
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    mountedRef.current && fetchAllRewards();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serializer]);

  // PART OF LOCAL REWARD API
  useEffect(() => {
    const uploadFile = async () => {
      setLoadingOnUpload(true);
      try {
        const { url } = await upload({ image: selectedFile });
        await updateRewardById(detail._id, {
          avatar: url,
        });
        mountedRef.current &&
          setDetail({
            ...detail,
            avatar: url,
          });
      } catch (error) {
        console.log(error);
      }
      setLoadingOnUpload(false);
    };

    selectedFile && mountedRef.current && uploadFile();
    return () => {
      setSelectedFile(null);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  useEffect(() => {
    const uploadFile = async () => {
      setLoading(true);
      try {
        const { url: icon } = await upload({ image: selectedFile });
        setSelectedReward({
          ...selectedReward,
          icon,
        });
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    selectedFile && mountedRef.current && uploadFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  const fetchAllRewards = async (params) => {
    try {
      const {
        rewards: { docs, ...paginate },
      } = await getAllRewards({ ...params }, serializer);
      setRewardList(docs);
      setPagination(paginate);
    } catch (error) {
      setRewardList(null);
    }
  };

  const fetchRewardListExport = async (params, reset = false) => {
    try {
      if (!rewardListExport || reset) {
        const limit = 999999999;
        const {
          rewards: { docs },
        } = await getAllRewards({ ...params, limit }, serializer);
        setRewardListExport(docs);

        return docs;
      }

      return rewardListExport;
    } catch (error) {
      mountedRef.current && setRewardListExport(null);
    }
  };

  const fetchCommunityList = async (params) => {
    try {
      const serializer = {
        name: params,
      };
      const {
        communities: { docs, ...paginate },
      } = await getAllCommunities({ ...params }, serializer);
      setOptions2(docs);

      return docs;
    } catch (e) {
      setOptions2([]);
      swal(e.message);
    }
  };

  const handleSearch = (keyword) => {
    setOrder("asc");
    setOrderBy("");
    setSerializer({
      ...serializer,
      title: {
        like: keyword,
      },
    });
  };

  // API NAME MUST BE UPDATED

  // API NAME MUST BE UPDATED

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    fetchAllRewards({
      sort: `${isAsc ? "" : "-"}${property}`,
    });
  };

  const initCreateReward = () => {
    setSelectedReward({});
    handleToggleModal();
  };

  const handleChangeFields = async (e) => {
    if (!(e.target.name === "code" && e.target.value.length > 5)) {
      setSelectedReward({
        ...selectedReward,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleChangeFields2 = async (value) => {
    {
      setSelectedReward({
        ...selectedReward,
        communityId: value.value,
      });
    }
  };

  const handleChangeCategory = async (e) => {
    setSelectedReward({
      ...selectedReward,
      category: e?.category,
    });
  };

  const handleChangeCommunities = async (value) => {
    const arrayOfCommunityId = [];
    value.forEach((community) => {
      arrayOfCommunityId.push(community._id);
    });
    const newSelectedReward = selectedReward;
    newSelectedReward.communityId = arrayOfCommunityId;
    setSelectedReward({
      ...newSelectedReward,
    });
  };

  const validateAction = async () => {
    setLoading(true);
    try {
      if (modalState.isEdit) {
        await updateRewardById(selectedReward._id, selectedReward);
      } else {
        await createNewReward(selectedReward);
      }
      fetchAllRewards();
      fetchRewardListExport({}, true);
      handleToggleModal();
    } catch (error) {
      console.log({ error });
    }
    setLoading(false);
  };

  const handleInfoPage = (reward) => {
    setSelectedReward(reward);
    setModalState({
      isShow: !modalState.isShow,
      isEdit: true,
    });
  };

  const openModalDelete = (reward) => {
    setSelectedReward(reward);
    setDel(true);
  };

  const closeModalDelete = () => {
    setSelectedReward({
      icon: "",
      title: "",
      score: 0,
      description: "",
      isActive: true,
    });

    setDel(false);
  };

  const handleDelete = async () => {
    try {
      await deleteRewardById(selectedReward._id);
    } catch (err) {
      // do something
    }

    closeModalDelete();

    setLoading(true);
    await fetchAllRewards();
    fetchRewardListExport({}, true);
    setLoading(false);
  };

  const handleToggleModal = () => {
    setModalState({
      isShow: !modalState.isShow,
      isEdit: false,
    });
  };
  const classes = useStyles();
  return (
    <>
      <Grid container alignItems="center" classes={{ root: styles.container }}>
        <PageHeader
          title="Reward Management"
          onAddNew={initCreateReward}
          onSearch={handleSearch}
          csvReport={{
            headers,
            data: rewardListExport,
            filename: "Reward List.csv",
            fn: fetchRewardListExport,
          }}
        ></PageHeader>
        <Grid item xs={12}>
          <DataTable
            headers={headers}
            data={rewardList}
            onInfo={handleInfoPage}
            onDelete={openModalDelete}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            onChangePage={(e, newPage) =>
              setSerializer({ ...serializer, page: newPage + 1 })
            }
            {...pagination}
          />
        </Grid>
      </Grid>

      <ModalDelete
        content="Reward"
        open={del}
        onCancel={closeModalDelete}
        onDelete={handleDelete}
      ></ModalDelete>

      <StyledModal open={modalState.isShow} onCancel={handleToggleModal}>
        <div className={styles.modal}>
          {isLoading ? (
            <>
              <CircularProgress style={{ marginTop: "25px" }} />
              <Typography className={cx(styles.label, styles.alignLeft)}>
                Please wait...
              </Typography>
            </>
          ) : (
            <>
              <IconButton
                size="small"
                onClick={handleToggleModal}
                className={styles.close}
              >
                <HighlightOff
                  fontSize="large"
                  style={{ color: "#320064" }}
                ></HighlightOff>
              </IconButton>
              <Badge
                overlap="circle"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                badgeContent={
                  <IconButton
                    className={styles.edit}
                    onClick={() => file.current.click()}
                    size="small"
                  >
                    <Avatar
                      src={pencil}
                      alt="pencil"
                      style={{ height: "30px", width: "30px" }}
                    />
                  </IconButton>
                }
                style={{ marginBottom: "30px" }}
              >
                <Avatar
                  alt="Community Avatar"
                  src={
                    selectedReward?.icon ||
                    `http://i0.wp.com/${
                      selectedReward?.avatar?.slice(8) ||
                      "via.placeholder.com/300"
                    }?w=300`
                  }
                  className={styles.avatar}
                />
              </Badge>
              <input
                type="file"
                id="file"
                ref={file}
                style={{ display: "none" }}
                onChange={(e) => setSelectedFile(e.target.files[0])}
              />
              {fields.map((field) =>
                field?.key === "category" ? (
                  <Grid
                    item
                    xs={12}
                    key={field.key}
                    style={{ marginBottom: "10px" }}
                  >
                    <Typography className={cx(styles.label, styles.alignLeft)}>
                      {field.label}
                    </Typography>
                    <Autocomplete
                      classes={classes}
                      id="combo-box-demo"
                      options={options}
                      defaultValue={options[0]}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.category}
                      onChange={(event, value) => {
                        handleChangeCategory(value);
                      }}
                      style={{ width: 420, marginLeft: 25 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Combo box"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={12}
                    key={field.key}
                    style={{ marginBottom: "10px", padding: "0 25px" }}
                  >
                    <Typography className={cx(styles.label, styles.alignLeft)}>
                      {field.label}
                    </Typography>
                    <FormControl variant="outlined" fullWidth>
                      <OutlinedInput
                        type={field.type}
                        placeholder={field.label}
                        className={styles.fields}
                        value={selectedReward[field.key] || ""}
                        name={field.key}
                        onChange={handleChangeFields}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              className={styles.edit}
                              size="small"
                              disabled
                            >
                              <Avatar
                                src={pencil}
                                alt="pencil"
                                style={{ height: "20px", width: "20px" }}
                              />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                )
              )}

              {fields2.map((field) =>
                field?.key === "communityId" ? (
                  <Grid
                    item
                    xs={12}
                    key={field.key}
                    style={{ marginBottom: "10px" }}
                  >
                    <Typography className={cx(styles.label, styles.alignLeft)}>
                      {field.label}
                    </Typography>
                    <Autocomplete
                      classes={classes}
                      // style={{ width: 50}
                      multiple
                      id="checkboxes-tags-demo"
                      filterSelectedOptions={true}
                      options={options2}
                      defaultValue={options2?.filter((val1) =>
                        detail?.name.some(
                          (val2) => val1._id.toString() === val2._id.toString()
                        )
                      )}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        handleChangeCommunities(value);
                      }}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </React.Fragment>
                      )}
                      style={{ width: 400, alignSelf: "center" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Add communities"
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={12}
                    key={field.key}
                    style={{ marginBottom: "10px" }}
                  >
                    <Typography className={cx(styles.label, styles.alignLeft)}>
                      {field.label}
                    </Typography>
                    <FormControl variant="outlined" fullWidth>
                      <OutlinedInput
                        value={detail[field.key]}
                        type={field.type}
                        placeholder={field.label}
                        className={styles.input}
                        disabled={field.key !== editField?.key}
                        onChange={(e) =>
                          handleChangeFields2(field.key, e.target.value)
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            {isLoadingOnSave && field.key === editField?.key ? (
                              <CircularProgress
                                size={20}
                                color="secondary"
                              ></CircularProgress>
                            ) : (
                              <IconButton
                                className={
                                  field.key !== editField?.key
                                    ? styles.edit
                                    : styles.done
                                }
                                size="small"
                                disabled={
                                  field.key !== editField?.key &&
                                  !!editField &&
                                  isLoadingOnSave
                                }
                                onChange={(value) => {
                                  handleChangeCommunities(value);
                                }}
                              >
                                {field.key === editField?.key ? (
                                  <Done
                                    fontSize="inherit"
                                    style={{ color: "#ffffff" }}
                                  ></Done>
                                ) : (
                                  <Avatar
                                    src={pencil}
                                    alt="pencil"
                                    style={{ height: "20px", width: "20px" }}
                                  />
                                )}
                              </IconButton>
                            )}
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                )
              )}

              <Button
                variant="contained"
                classes={{
                  root: cx(styles.btn, styles.create),
                  label: styles.btnLabel,
                }}
                onClick={validateAction}
              >
                {modalState.isEdit ? "Save" : "Create"}
              </Button>
            </>
          )}
        </div>
      </StyledModal>
    </>
  );
};

export default Rewards;
