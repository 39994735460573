import { server } from './index'

const updateSentRewardById = async (id) => {
  try {
    const { data } = await server({
      method: 'PUT',
      url: `/reports/rewards/${id}/sent`,
      data: {}
    })
    return data
  } catch (error) {
    return error
  }
}

export default updateSentRewardById