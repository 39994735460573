import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  Login,
  Overview,
  Communities,
  CommunityInfo,
  InternalSkills,
  CommunityActivities,
  CommunityMembers,
  CommunityRewards,
  Users,
  UserInfo,
  Rewards,
  Activities,
  Reports,
  ReportReward,
  MotionReport,
} from "../views";
import AdminRoute from "./AdminRoute";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login}></Route>
        <AdminRoute path="/overview" component={Overview}></AdminRoute>
        <AdminRoute
          exact
          path="/communities"
          component={Communities}
        ></AdminRoute>
        <AdminRoute
          exact
          path="/communities/:code"
          component={CommunityInfo}
        ></AdminRoute>
        <AdminRoute
          path="/communities/:code/skills"
          component={InternalSkills}
        ></AdminRoute>
        <AdminRoute
          path="/communities/:code/activities"
          component={CommunityActivities}
        ></AdminRoute>
        <AdminRoute
          path="/communities/:code/members"
          component={CommunityMembers}
        ></AdminRoute>
        <AdminRoute
          path="/communities/:code/rewards"
          component={CommunityRewards}
        ></AdminRoute>
        <AdminRoute exact path="/users" component={Users}></AdminRoute>
        <AdminRoute path="/users/:id" component={UserInfo}></AdminRoute>
        <AdminRoute path="/rewards" component={Rewards}></AdminRoute>
        <AdminRoute path="/activities" component={Activities}></AdminRoute>
        <AdminRoute path="/customerreports" component={Reports}></AdminRoute>
        <AdminRoute path="/reportsreward" component={ReportReward}></AdminRoute>
        <AdminRoute path="/motionreport" component={MotionReport}></AdminRoute>
      </Switch>
    </Router>
  );
};

export default Routes;
