import { server } from './index'

const getCommunityByCode = async ({ code }) => {
  try {
    const { data } = await server({
      method: 'GET',
      url: `/communities/get/${code}`,
    })
    return data
  } catch (error) {

  }
}

export default getCommunityByCode