/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { PageHeader, DataTable } from "../../components";
import { getAllUsers } from "../../api";
import styles from "./Users.module.css";

const headers = [
  { label: "Joined", key: "createdAt", type: "text" },
  { label: "User Name", key: "fullName", type: "text" },
  { label: "User ID", key: "_id", type: "text" },
  { label: "Email", key: "email", type: "text" },
  { label: "Community", key: "communityId.name", type: "text" },
  { label: "Spendable Score", key: "score", type: "text" },
  { label: "Total Score", key: "fullScore", type: "text" },
];

const limitData = 100;

const Users = () => {
  const history = useHistory();
  const mountedRef = useRef(true);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("");
  const [userList, setUserList] = useState(null);
  const [userListExport, setUserListExport] = useState(null);
  const [pagination, setPagination] = useState({});
  const [serializer, setSerializer] = useState({});

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    mountedRef.current && fetchAllUsers({});
  }, [serializer]);

  const fetchAllUsers = async (params) => {
    try {
      setUserList(null);
      const {
        users: { docs, ...paginate },
      } = await getAllUsers({ ...params, limit: limitData }, serializer);
      if (mountedRef.current) {
        setUserList(docs);
        setPagination(paginate);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserListExport = async (params) => {
    try {
      if (!userListExport) {
        const limit = 999999999;
        const {
          users: { docs },
        } = await getAllUsers({ ...params, limit }, serializer);
        setUserListExport(docs);

        return docs;
      }

      return userListExport;
    } catch (error) {
      mountedRef.current && setUserListExport(null);
    }
  };

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    fetchAllUsers({
      sort: `${!isAsc ? "-" : ""}${
        property === "fullName" ? "firstName" : property
      }`,
    });
  };

  const handleSearch = (keyword) => {
    setOrder("asc");
    setOrderBy("");
    setSerializer({
      _id: {
        like: keyword,
      },
    });
  };

  const handleShowInfo = (data) => {
    history.push(`/users/${data.id}`);
  };

  return (
    <Grid container alignItems="center" className={styles.container}>
      <PageHeader
        title="User Management"
        onSearch={handleSearch}
        csvReport={{
          headers,
          data: userListExport,
          filename: "User List.csv",
          fn: fetchUserListExport,
        }}
      ></PageHeader>
      <Grid item xs={12}>
        <DataTable
          headers={headers}
          data={userList}
          total
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          onInfo={handleShowInfo}
          onChangePage={(e, newPage) =>
            setSerializer({ ...serializer, page: newPage + 1 })
          }
          {...pagination}
        />
      </Grid>
    </Grid>
  );
};

export default Users;
